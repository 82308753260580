import './Customers.scss';
import { useState, useEffect } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CustomerService from '../../../../Services/CustomerService';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import ChangeLogoModal from './ChangeLogoModal';
/* eslint-disable react/prop-types */

function CustomerEntry({ customer, onChangeLogoClick, mainCustomerName }) {
  return (
    <div className="customer-entry">
      <div className="content-column">
        <div className="title-row gap-row">
          <p className="title">{customer.name}</p>
          {mainCustomerName ? (
            <div className="tag">
              <p>
                Sub-customer för <b>{mainCustomerName}</b>
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="data-row">
          <div className="data-column">
            <p>
              Org. nummer: <b>{customer.organizationNumber ?? '-'}</b>
            </p>
            <p>
              Support email: <b>{customer.supportEmail ?? '-'}</b>
            </p>
            <p>
              Support telefonnummer: <b>{customer.supportPhoneNumber ?? '-'}</b>
            </p>
          </div>
          <div className="data-column">
            <p>
              Kontaktperson: <b>{customer.contactPerson ?? '-'}</b>
            </p>
            <p>
              Faktureringsmail: <b>{customer.billingEmail ?? '-'}</b>
            </p>
            <p>
              Telefonnummer: <b>{customer.phoneNumber ?? '-'}</b>
            </p>
          </div>
        </div>
        <div className="gap-row">
          <XpektorButton hollow content={`Användare (${customer.userCount})`} />
          <XpektorButton
            hollow
            content={`Klienter (${customer.clientCount})`}
          />
        </div>
      </div>
      <div className="logo-column">
        {customer.logo ? (
          <img className="logo" src={customer.logo} alt="Logo" />
        ) : (
          <div className="missing-logo">
            <p>Logga saknas</p>
          </div>
        )}
        <XpektorButton
          content={customer.logo ? 'Ändra' : 'Ladda upp'}
          hollow
          onClick={onChangeLogoClick}
        />
      </div>
    </div>
  );
}

function CustomerTable({ customers, onChangeLogoClick }) {
  return (
    <div className="customer-table-container">
      <div className="customer-table-header" />
      <div className="customer-list">
        {customers.map((customer) => {
          return (
            <CustomerEntry
              customer={customer}
              key={customer.id}
              onChangeLogoClick={() => onChangeLogoClick(customer)}
              mainCustomerName={
                customer.mainCustomerId !== null
                  ? customers.find(
                      (mainCustomer) =>
                        mainCustomer.id === customer.mainCustomerId
                    )?.name ?? ''
                  : ''
              }
            />
          );
        })}
      </div>
    </div>
  );
}

export default function Customers() {
  const [customers, setCustomers] = useState([]);
  const [showChangeLogoModal, setShowChangeLogoModal] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState({});

  const openChangeLogoModal = (customer) => {
    setCustomerToEdit(customer);
    setShowChangeLogoModal(true);
  };

  useEffect(() => {
    const getData = async () => {
      await CustomerService.getAllCustomers()
        .then((response) => {
          setCustomers(response.data);
        })
        .catch(() => {});
    };
    getData();
  }, []);
  return (
    <div className="system-admin-customers-wrapper">
      <div className="nav-row">
        <XpektorButton icon={faPlus} content="Skapa kund" />
      </div>
      <CustomerTable
        customers={customers}
        onChangeLogoClick={openChangeLogoModal}
      />
      {showChangeLogoModal ? (
        <ChangeLogoModal
          customer={customerToEdit}
          handleClose={() => {
            setShowChangeLogoModal(false);
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
}
