const testOrgNr = (value) => {
  const regex = /^([0-9]{6}[-]?[0-9]{4})$/g;
  return regex.test(value);
};

const testSSN = (value) => {
  const regex = /^([0-9]{8}[-]?[0-9]{4})$/g;
  return regex.test(value);
};

const testEmail = (value) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(value);
};

const testZipCode = (value) => {
  const regex = /^[0-9]{3}[ ]?[0-9]{2}$/;
  return regex.test(value);
};

const testIntegerInput = (value) => {
  const regex = /^(\d*)$/;
  return regex.test(value);
};

const testPhoneNrInput = (value) => {
  const regex = /^[+]?[0-9 -]*$/;
  return regex.test(value);
};

const Regex = {
  testOrgNr,
  testSSN,
  testEmail,
  testZipCode,
  testIntegerInput,
  testPhoneNrInput,
};

export default Regex;
