import './ChangePassword.scss';
import { useState, useEffect } from 'react';
import UserService from '../../../Services/UserService';
import {
  XpektorForm,
  FormInput,
  FormButton,
} from '../../../Components/XpektorForms/XpektorForms';

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');

  const [newPwValid, setNewPwValid] = useState(false);

  const [oldPwErrorText, setOldPwErrorText] = useState('');
  const [newPwErrorText, setNewPwErrorText] = useState('');
  const [repeatNewPwErrorText, setRepeatNewPwErrorText] = useState('');

  useEffect(() => {
    document.title = 'Byt lösenord';
  }, []);

  function validateForm() {
    return (
      oldPassword.length > 0 &&
      newPassword.length > 0 &&
      repeatNewPassword.length > 0 &&
      newPwValid
    );
  }

  function clearErrors() {
    setOldPwErrorText('');
    setNewPwErrorText('');
    setRepeatNewPwErrorText('');
  }

  function validateNewPassword(newPw) {
    if (newPw.length < 8) {
      setNewPwErrorText('Det nya lösenordet måste innehålla minst 8 tecken.');
      setNewPwValid(false);
      return;
    }
    if (newPw.length > 250) {
      setNewPwErrorText('Det nya lösenordet är för långt.');
      setNewPwValid(false);
      return;
    }

    setNewPwValid(true);
    setNewPwErrorText('');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const oldPwCorrect = await UserService.validatePw(oldPassword);
    if (!oldPwCorrect) {
      setOldPassword('');
      clearErrors();
      setOldPwErrorText('Nuvarande lösenord var inte korrekt.');
      return;
    }

    if (newPassword === oldPassword) {
      setNewPassword('');
      setRepeatNewPassword('');
      clearErrors();
      setNewPwValid(false);
      setNewPwErrorText('Det nya lösenordet är samma som förra.');
      return;
    }

    if (newPassword !== repeatNewPassword) {
      setNewPassword('');
      setRepeatNewPassword('');
      clearErrors();
      setNewPwValid(false);
      setRepeatNewPwErrorText('Det nya lösenordet matchar inte bekräftelsen.');
      return;
    }

    UserService.changePw(oldPassword, newPassword)
      .then((response) => {
        if (response) {
          alert('Lösenord ändrat');
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          alert('Kunde inte ändra lösenord');
        }
        if (error.response.status === 500) {
          alert('Något gick fel, försök igen senare');
        }
      });
  };

  return (
    <div className="change-password-wrapper">
      <div className="change-password-head">
        <p className="change-password-title">Byt lösenord</p>
      </div>
      <div className="change-pw-content">
        <div className="change-pw-form-container">
          <XpektorForm onSubmit={handleSubmit}>
            <FormInput
              label="Nuvarande lösenord"
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              infoText={oldPwErrorText}
            />
            <FormInput
              label="Nytt lösenord"
              type="password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
                validateNewPassword(e.target.value);
              }}
              infoText={newPwErrorText}
            />
            <FormInput
              label="Bekräfta nytt lösenord"
              type="password"
              value={repeatNewPassword}
              onChange={(e) => setRepeatNewPassword(e.target.value)}
              infoText={repeatNewPwErrorText}
            />
            <FormButton text="Bekräfta" disabled={!validateForm()} />
          </XpektorForm>
        </div>
      </div>
    </div>
  );
}
