/* eslint-disable react/prop-types */
import './AdminSettings.scss';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TabNav from '../../../Components/TabNav/TabNav';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import ClientSettings from './ClientSettings/ClientSettings';
import UserSettings from './UserSettings/UserSettings';
import AuthLinkSettings from './AuthLinkSettings/AuthLinkSettings';
import XpektorSlider from '../../../Components/XpektorSlider/XpektorSlider';
import UserService from '../../../Services/UserService';

export default function AdminSettings() {
  const [activeTab, setActiveTab] = useState('');
  const history = useHistory();
  const isLocation = (path) => {
    return history.location.pathname.includes(path);
  };

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.getCurrentUser();
      if (user.role < 1) {
        history.push(`/dashboard`);
      }

      if (isLocation('general')) {
        setActiveTab('general');
      } else if (isLocation('clients')) {
        setActiveTab('clients');
      } else if (isLocation('users')) {
        setActiveTab('users');
      } else if (isLocation('links')) {
        setActiveTab('links');
      }
    };

    document.title = 'Admin';
    getData();
  }, []);

  function renderSettings() {
    return (
      <XpektorSlider
        activeSlideIndex={
          activeTab === 'general'
            ? 0
            : activeTab === 'clients'
            ? 1
            : activeTab === 'users'
            ? 2
            : activeTab === 'links'
            ? 3
            : ''
        }
      >
        <XpektorSlider.Slide hidden={activeTab !== 'general'}>
          <GeneralSettings />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeTab !== 'clients'}>
          <ClientSettings />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeTab !== 'users'}>
          <UserSettings />
        </XpektorSlider.Slide>
        <XpektorSlider.Slide hidden={activeTab !== 'links'}>
          <AuthLinkSettings />
        </XpektorSlider.Slide>
      </XpektorSlider>
    );
  }

  return (
    <div className="admin-settings-wrapper">
      <div className="admin-settings-head">
        <p className="admin-settings-title">Admininställningar</p>
      </div>
      <div className="admin-nav-container">
        <TabNav>
          <TabNav.Tab
            title="Allmänt"
            active={activeTab === 'general'}
            onClick={() => {
              setActiveTab('general');
              history.push(`/settings/general`);
            }}
          />
          <TabNav.Tab
            title="Klienter"
            active={activeTab === 'clients'}
            onClick={() => {
              setActiveTab('clients');
              history.push(`/settings/clients`);
            }}
          />
          <TabNav.Tab
            title="Användare"
            active={activeTab === 'users'}
            onClick={() => {
              setActiveTab('users');
              history.push(`/settings/users`);
            }}
          />
          <TabNav.Tab
            title="Länkar"
            active={activeTab === 'links'}
            onClick={() => {
              setActiveTab('links');
              history.push(`/settings/links`);
            }}
          />
        </TabNav>
      </div>
      {renderSettings()}
    </div>
  );
}
