/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import XpektorButton from '../../../../Components/XpektorButton/XpektorButton';
import CustomerService from '../../../../Services/CustomerService';
import UserService from '../../../../Services/UserService';
import AddSupportInfoModal from '../../Modals/AddSupportInfoModal';
import { TranslateKYCTriggers } from '../../../../Components/Utilities/KYCTriggerTexts';

const standaloneTriggersIds = [1, 2, 7, 22, 23];
const warningTriggers = [1, 2];

function BoardMemberDeviationTrigger({ deviation }) {
  return (
    <div className="trigger-container">
      <p className="trigger-title error">Styrelse</p>
      <p className="trigger-text">
        <b>{deviation.name}</b> finns {deviation.type === 1 ? 'inte' : ''} hos
        Bolagsverket men har {deviation.type === 1 ? '' : 'inte'} fyllts i.
      </p>
    </div>
  );
}

function StandaloneTrigger({ trigger }) {
  const triggerType = warningTriggers.includes(trigger.id)
    ? 'warning'
    : 'error';
  return (
    <div className="trigger-container">
      <p className={`trigger-title ${triggerType}`}>
        {triggerType === 'warning' ? 'Varning' : 'Avvikelse'}
      </p>
      <p className="trigger-text">{trigger.texts.name}</p>
    </div>
  );
}

export default function KYCView({ kycStatus, onCreateClick }) {
  const [kycFormUrl, setKYCFormUrl] = useState('');
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [showAddSupportInfoModal, setShowAddSupportInfoModal] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [translatedTriggers, setTranslatedTriggers] = useState([]);

  useEffect(() => {
    if (kycStatus !== null && Object.keys(kycStatus).length > 0) {
      setKYCFormUrl(
        `${window.location.protocol}//${window.location.host}/kyc-form?state=${kycStatus.stateId}`
      );
      const triggersToTranslate = kycStatus.triggers.filter((trigger) =>
        standaloneTriggersIds.includes(Number(trigger))
      );
      setTranslatedTriggers(TranslateKYCTriggers(triggersToTranslate));
      setIsCreatingNew(false);
    }
  }, [kycStatus]);

  const [animatingCopied, setAnimatingCopied] = useState(false);
  const [animatingCopiedCount, setAnimatingCopiedCount] = useState(0);

  useEffect(() => {
    const animateCopied = () => {
      const newAnimatingCopiedCount = animatingCopiedCount + 1;
      if (newAnimatingCopiedCount === 2) {
        setAnimatingCopiedCount(0);
        setAnimatingCopied(false);
      } else {
        setAnimatingCopiedCount(newAnimatingCopiedCount);
      }
    };
    const timer = setTimeout(() => animatingCopied && animateCopied(), 1000);
    return () => clearTimeout(timer);
  }, [animatingCopiedCount, animatingCopied]);

  async function ValidateCustomerAndCreateNewProcess() {
    setIsCreatingNew(true);

    const user = await UserService.getCurrentUser();
    await CustomerService.getCustomer(user.customerId)
      .then((response) => {
        if (!response.data.supportPhoneNumber || !response.data.supportEmail) {
          setCustomerData(response.data);
          setShowAddSupportInfoModal(true);
          return;
        }
        onCreateClick();
      })
      .catch(() => {});
  }

  const updateCustomerAndCreateNewProcess = async (supportInfo) => {
    const newCustomer = { ...customerData };
    newCustomer.supportEmail = supportInfo.email;
    newCustomer.supportPhoneNumber = supportInfo.phone;

    await CustomerService.updateCustomer(newCustomer)
      .then(() => {
        onCreateClick();
        setShowAddSupportInfoModal(false);
      })
      .catch(() => {});
  };

  function TranslateFormType(type) {
    return type === 0
      ? 'Lån'
      : type === 1
      ? 'Företagskredit'
      : type === 2
      ? 'Factoring'
      : type === 3
      ? 'Orderfinansiering'
      : type === 4
      ? 'Leasing'
      : 'N/A';
  }

  return (
    <div className="kyc-card">
      <div className="main-column">
        <div className="title-row">
          <h2>KYC</h2>
          {kycStatus !== null ? (
            <div
              className={`status-sign ${
                kycStatus.signed
                  ? 'signed-status'
                  : kycStatus.submitted
                  ? 'signing-status'
                  : ''
              }`}
            >
              <p className="data-title">Status:</p>
              <p className="status-text">
                {kycStatus.signed
                  ? 'Signerat'
                  : kycStatus.submitted
                  ? 'Väntar på underskrift'
                  : 'Ej ifyllt'}
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
        {kycStatus === null ? (
          <p className="empty-text">Finns ingen aktiv KYC process.</p>
        ) : Object.keys(kycStatus).length === 0 ? (
          <p className="empty-text">Hämtar...</p>
        ) : (
          <div className="data-column">
            <div className="entry-column">
              <p className="data-title">Formulärtyp:</p>
              <p>
                {kycStatus.formType !== null
                  ? TranslateFormType(kycStatus.formType)
                  : 'N/A'}
              </p>
            </div>
            <div className="entry-column">
              <p className="data-title">Datum skapat:</p>
              <p>
                {Moment.utc(kycStatus.dateCreated).local().format('YYYY-MM-DD')}
              </p>
            </div>
            <div className="entry-column">
              <p className="data-title">Senast besökt:</p>
              <p>
                {kycStatus.lastAccessed
                  ? Moment.utc(kycStatus.lastAccessed)
                      .local()
                      .format('YYYY-MM-DD')
                  : 'N/A'}
              </p>
            </div>
            <div className="entry-column">
              <p className="data-title">Datum signerat:</p>
              <p>
                {kycStatus.dateSealed
                  ? Moment.utc(kycStatus.dateSealed)
                      .local()
                      .format('YYYY-MM-DD')
                  : 'N/A'}
              </p>
            </div>
          </div>
        )}
        <div className="action-row">
          {kycStatus === null ? (
            <>
              <XpektorButton
                content="Starta ny process"
                onClick={() => {
                  ValidateCustomerAndCreateNewProcess();
                }}
                loading={isCreatingNew}
              />
              <p className="price-text">
                OBS! För varje skapad KYC process tillkommer en kostnad på 129kr
                på nästa faktura.
              </p>
            </>
          ) : (
            <>
              {kycStatus.submitted === false ? (
                <>
                  <XpektorButton
                    icon={!animatingCopied ? faCopy : ''}
                    content={animatingCopied ? 'Kopierad!' : 'Kopiera länk'}
                    onClick={() => {
                      navigator.clipboard.writeText(kycFormUrl);
                      setAnimatingCopied(true);
                    }}
                  />
                  <XpektorButton
                    content="Öppna formuläret"
                    onClick={() => {
                      window.open(kycFormUrl, '_blank', 'noopener,noreferrer');
                    }}
                  />
                </>
              ) : (
                ''
              )}
              {kycStatus.signed === true ? (
                <XpektorButton
                  content="Granska dokument"
                  hollow
                  onClick={() => {
                    if (kycStatus.downloadLink) {
                      window.open(
                        kycStatus.downloadLink,
                        '_blank',
                        'noopener,noreferrer'
                      );
                    }
                  }}
                />
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </div>
      <div className="trigger-column">
        {kycStatus !== null &&
        Object.keys(kycStatus).length !== 0 &&
        kycStatus.signed ? (
          <>
            <p className="trigger-column-title">Avvikelser</p>
            <div className="trigger-list">
              {kycStatus.triggers.length === 0 ? (
                <div className="no-triggers-sign">
                  <FontAwesomeIcon className="icon" icon={faCircleCheck} />
                  <p>Finns inga</p>
                </div>
              ) : (
                <>
                  {translatedTriggers.map((trigger) => {
                    return (
                      <StandaloneTrigger
                        trigger={trigger}
                        key={`kyc-standalone-trigger-${trigger.id}`}
                      />
                    );
                  })}
                  {kycStatus.boardMemberDeviations.map((deviation) => {
                    return (
                      <BoardMemberDeviationTrigger
                        deviation={deviation}
                        key={deviation.id}
                      />
                    );
                  })}
                </>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      {showAddSupportInfoModal ? (
        <AddSupportInfoModal
          handleClose={() => {
            setShowAddSupportInfoModal(false);
            setIsCreatingNew(false);
          }}
          onSubmit={updateCustomerAndCreateNewProcess}
          customer={customerData}
        />
      ) : (
        ''
      )}
    </div>
  );
}
