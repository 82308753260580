/* eslint-disable react/prop-types */
import './XpektorForm.scss';
import './MinimalForm.scss';
import './ClassicForm.scss';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export function XpektorForm({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="xpektor-form">
      {children}
    </form>
  );
}

export function MinimalForm({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="minimal-form">
      {children}
    </form>
  );
}

export function ClassicForm({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="classic-form">
      {children}
    </form>
  );
}

export function FormInput({
  label,
  type,
  placeholder,
  value,
  onChange,
  icon,
  onIconClick,
  infoText,
  infoOk,
  maxLength,
  regex,
  width,
}) {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (value) {
      setFocused(true);
    }
  }, [value]);
  return (
    <div
      className={`form-input-container ${focused ? 'input-focus' : ''}`}
      style={width ? { width: `${width}px` } : {}}
    >
      <label htmlFor={label}>{label}</label>
      <div className="form-input">
        <input
          id={label}
          type={regex ? 'text' : type}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={(e) => {
            if (onChange) {
              if (regex && !regex(e.target.value)) {
                return;
              }
              onChange(e);
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            if (value?.length === 0) setFocused(false);
          }}
        />
        {icon ? (
          <FontAwesomeIcon
            className={`form-input-icon ${onIconClick ? 'clickable-icon' : ''}`}
            icon={icon}
            onClick={onIconClick}
          />
        ) : (
          ''
        )}
      </div>
      <div className={`input-info-container ${infoOk && 'input-info-ok'}`}>
        {infoText ? (
          <>
            <FontAwesomeIcon className="input-info-icon" icon={faCircleInfo} />
            <p>{infoText}</p>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export function FormTextArea({ label, value, onChange, infoText, rows }) {
  const [focused, setFocused] = useState(false);

  return (
    <div className={`form-text-area-container ${focused ? 'input-focus' : ''}`}>
      <label htmlFor={label}>{label}</label>
      <textarea
        id={label}
        value={value}
        rows={rows}
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          if (value?.length === 0) setFocused(false);
        }}
      />
      <div className="input-info-container">
        {infoText ? (
          <>
            <FontAwesomeIcon className="input-info-icon" icon={faCircleInfo} />
            <p>{infoText}</p>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export function FormDropdown({ label, children, onChange, defaultValue }) {
  return (
    <div className="form-dropdown-container">
      <label htmlFor={label}>{label}</label>
      <select
        id={label}
        defaultValue={defaultValue}
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
      >
        {children}
      </select>
    </div>
  );
}

export function FormFileUpload({ label, onChange, infoText }) {
  return (
    <div className="form-file-upload-container">
      <label htmlFor={label}>{label}</label>
      <input
        id={label}
        type="file"
        accept=".pdf,.png,.jpg,.jpeg"
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
      />
      <div className="input-info-container">
        {infoText ? (
          <>
            <FontAwesomeIcon className="input-info-icon" icon={faCircleInfo} />
            <p>{infoText}</p>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export function FormDateInput({ label, value, onChange, infoText }) {
  return (
    <div className="form-date-input-container">
      <label htmlFor={label}>{label}</label>
      <input
        id={label}
        type="date"
        value={value}
        max="2999-12-31"
        onChange={(e) => {
          if (onChange) onChange(e);
        }}
      />
      <div className="input-info-container">
        {infoText ? (
          <>
            <FontAwesomeIcon className="input-info-icon" icon={faCircleInfo} />
            <p>{infoText}</p>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export function FormButton({ text, disabled }) {
  return (
    <input
      className="xpektor-form-submit-button"
      type="submit"
      value={text}
      disabled={disabled}
    />
  );
}
