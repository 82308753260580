import { Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './Pages/WebApp/Dashboard/Dashboard';
import CompanyInfo from './Pages/WebApp/CompanyInfo/CompanyInfo';
import ChangePassword from './Pages/WebApp/ChangePassword/ChangePassword';
import AdminSettings from './Pages/WebApp/Settings/AdminSettings';
import SystemAdmin from './Pages/WebApp/SystemAdmin/SystemAdmin';
import Faq from './Pages/WebApp/FAQ/Faq';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route>
      <Route exact path="/companyinfo/:clientId">
        <CompanyInfo />
      </Route>
      <Route exact path="/settings/:tab">
        <AdminSettings />
      </Route>
      <Route exact path="/systemadmin/:systemtab">
        <SystemAdmin />
      </Route>
      <Route extact path="/FAQ">
        <Faq />
      </Route>
      <Route exact path="/changePassword">
        <ChangePassword />
      </Route>
      <Route path="*">
        <Redirect to="/not-found" />
      </Route>
    </Switch>
  );
}
