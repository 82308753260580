/* eslint-disable react/prop-types */
import './XpektorButton.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function XpektorButton({
  onClick,
  content,
  icon,
  disabled,
  loading,
  styleClass,
  hollow,
}) {
  return (
    <div className="xpektor-button">
      <button
        className={`${
          hollow ? 'hollow-button' : 'filled-button'
        } ${styleClass}`}
        type="button"
        onClick={onClick}
        disabled={disabled || loading}
      >
        {icon && <FontAwesomeIcon className="icon" icon={icon} />}

        {loading ? (
          <FontAwesomeIcon className="loading-icon" icon={faSpinner} />
        ) : (
          content
        )}
      </button>
    </div>
  );
}
