import './KYCForm.scss';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import AuthService from '../../../Services/AuthService';
import {
  ClassicForm,
  FormInput,
  FormTextArea,
  FormFileUpload,
  FormDropdown,
  FormDateInput,
} from '../../../Components/XpektorForms/XpektorForms';
import RadioButton from '../../../Components/RadioButton/RadioButton';
import XpektorButton from '../../../Components/XpektorButton/XpektorButton';
import KYCService from '../../../Services/KYCService';
import LoadingBar from '../../../Components/LoadingBar/LoadingBar';
import Regex from '../../../Components/Utilities/Regex';

export default function KYCForm() {
  const maxSteps = 5;
  const [formStep, setFormStep] = useState(1);
  const stepRef = useRef();

  const [loading, setLoading] = useState(true);
  const [kycState, setKYCState] = useState({});
  const { search } = useLocation();
  const state = new URLSearchParams(search).get('state');

  const [pageTitle, setPageTitle] = useState('');

  const [formType, setFormType] = useState('0');
  const [companyName, setCompanyName] = useState('');
  const [orgNr, setOrgNr] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [bankAccountType, setBankAccountType] = useState(1);
  const [accountNumber, setAccountNumber] = useState('');
  const [accountProofFile, setAccountProofFile] = useState(null);
  const [contactPerson, setContactPerson] = useState('');
  const [phoneNrContactPerson, setPhoneNrContactPerson] = useState('');
  const [webAddress, setWebAddress] = useState('');
  const [eCommercePlatform, setECommercePlatform] = useState('');
  const [settlementEmail, setSettlementEmail] = useState('');
  const [incorporationDate, setIncorporationDate] = useState('');
  const [nrEmployees, setNrEmployees] = useState('');
  const [
    estimatedMonthlyRevenueInstitute,
    setEstimatedMonthlyRevenueInstitute,
  ] = useState('');
  const [averageAmountInvoice, setAverageAmountInvoice] = useState('');
  const [nrOfTransactionsInstitute, setNrOfTransactionsInstitute] =
    useState('');
  const [businessAbroad, setBusinessAbroad] = useState(false);
  const [businessAbroadDescription, setBusinessAbroadDescription] =
    useState('');
  const [operationsOutsideEUEES, setOperationsOutsideEUESS] = useState(false);
  const [
    operationsOutsideEUEESDescription,
    setOperationsOutsideEUESSDescription,
  ] = useState('');
  const [coreBusinessDescription, setCoreBusinessDescription] = useState('');
  const [businessFinanceDescription, setBusinessFinanceDescription] =
    useState('');
  const [loanPurpose, setLoanPurpose] = useState('');

  const [foreignTransactionAmount, setForeignTransactionAmount] = useState(10);
  const [nrOfForeignTransactions, setNrOfForeignTransactions] = useState(0);
  const [currencyExchange, setCurrencyExchange] = useState(false);
  const [cryptoCurrencyExchange, setCryptoCurrencyExchange] = useState(false);
  const [handlingCash, setHandlingCash] = useState(false);
  const [cashMonthly, setCashMonthly] = useState('');
  const [nrOfExtraRepayments, setNrOfExtraRepayments] = useState(0);
  const [companyHierarchyDescription, setCompanyHierarchyDescription] =
    useState('');
  const [companyHierarchyFile, setCompanyHierarchyFile] = useState(null);
  const [detailedBusinessDescription, setDetailedBusinessDescription] =
    useState('');
  const [detailedLoanPurpose, setDetailedLoanPurpose] = useState('');
  const [futurePlans, setFuturePlans] = useState('');
  const [estimatedRevenueThreeMonths, setEstimatedRevenueThreeMonths] =
    useState('');
  const [estimatedRevenueSixMonths, setEstimatedRevenueSixMonths] =
    useState('');
  const [estimatedRevenueTwelveMonths, setEstimatedRevenueTwelveMonths] =
    useState('');
  const [estimatedResultThreeMonths, setEstimatedResultThreeMonths] =
    useState('');
  const [estimatedResultSixMonths, setEstimatedResultSixMonths] = useState('');
  const [estimatedResultTwelveMonths, setEstimatedResultTwelveMonths] =
    useState('');

  const [revenueMonthly, setRevenueMothly] = useState('');
  const [sellingService, setSellingService] = useState(false);
  const [sellingProduct, setSellingProduct] = useState(false);
  const [otherRevenueSource, setOtherRevenueSource] = useState(false);
  const [serviceDescription, setServiceDescription] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [otherRevenueSourceDescription, setOtherRevenueSourceDescription] =
    useState('');
  const [nrOfWithdrawals, setNrOfWithdrawals] = useState('');

  const [boardMembers, setBoardMembers] = useState([
    {
      id: 'board-member-0',
      firstName: '',
      lastName: '',
      socSecurityNumber: '',
      isSignatory: true,
    },
  ]);
  function AddBoardMemberEntry() {
    setBoardMembers([
      ...boardMembers,
      {
        id: `board-member-${boardMembers.length}`,
        firstName: '',
        lastName: '',
        socSecurityNumber: '',
        isSignatory: true,
      },
    ]);
  }

  const [otherSignatoriesExist, setOtherSignatoriesExist] = useState(false);
  const [otherSignatories, setOtherSignatories] = useState([]);
  function AddOtherSignatoryEntry() {
    setOtherSignatories([
      ...otherSignatories,
      {
        id: `other-signatory-${otherSignatories.length}`,
        firstName: '',
        lastName: '',
        socSecurityNumber: '',
      },
    ]);
  }

  const [beneficialOwnerPersons, setBeneficialOwnerPersons] = useState([]);
  const [beneficialOwnerCompanies, setBeneficialOwnerCompanies] = useState([]);
  function AddBeneficialOwnerPersonEntry() {
    setBeneficialOwnerPersons([
      ...beneficialOwnerPersons,
      {
        id: `beneficial-owner-persons-${beneficialOwnerPersons.length}`,
        firstName: '',
        lastName: '',
        socSecurityNumber: '',
        shares: '',
        PEP: false,
        RCA: false,
      },
    ]);
  }
  function AddBeneficialOwnerCompanyEntry() {
    setBeneficialOwnerCompanies([
      ...beneficialOwnerCompanies,
      {
        id: `beneficial-owner-companies-${beneficialOwnerCompanies.length}`,
        name: '',
        orgNumber: '',
        shares: '',
      },
    ]);
  }

  const [otherInfluentialOwnersPresent, setOtherInfluentialOwnersPresent] =
    useState(false);
  const [otherInfluentialOwnerPersons, setOtherInfluentialOwnerPersons] =
    useState([]);
  const [otherInfluentialOwnerCompanies, setOtherInfluentialOwnerCompanies] =
    useState([]);
  function AddOtherInfluentialPerson() {
    setOtherInfluentialOwnerPersons([
      ...otherInfluentialOwnerPersons,
      {
        id: `other-influential-owner-person-${otherInfluentialOwnerPersons.length}`,
        firstName: '',
        lastName: '',
        socSecurityNumber: '',
        PEP: false,
        RCA: false,
        descriptionOfInfluenceInCompany: '',
      },
    ]);
  }
  function AddOtherInfluentialCompany() {
    setOtherInfluentialOwnerCompanies([
      ...otherInfluentialOwnerCompanies,
      {
        id: `other-influential-owner-company-${otherInfluentialOwnerCompanies.length}`,
        name: '',
        orgNumber: '',
        descriptionOfInfluenceInCompany: '',
      },
    ]);
  }

  function AutofillData(data) {
    setCompanyName(data.companyName ?? '');
    setOrgNr(data.orgNumber ?? '');
    setAddress(data.address ?? '');
    setCity(data.city ?? '');
    setZipCode(data.zipCode ?? '');
    setIncorporationDate(data.incorporationDate ?? '');
    setNrEmployees(data.nrEmployees ?? '');
  }

  useEffect(() => {
    const decodeState = async () => {
      if (state) {
        const response = await AuthService.decodeKYC(state);
        if (response != null) {
          setKYCState(response);
          const newPageTitle = `${response.customerName
            .split(' ')
            .filter((word) => word !== 'AB')
            .join(' ')} kundkännedom`;
          document.title = newPageTitle;
          setPageTitle(newPageTitle);
          if (response.autofillData !== null) {
            AutofillData(response.autofillData);
          }
        } else {
          setKYCState(null);
          document.title = 'Ej giltig länk';
        }
        setLoading(false);
      }
    };
    decodeState();
  }, [state]);

  const [possibleSubjects, setPossibleSubjects] = useState([]);

  useEffect(() => {
    const newPossibleSubjects = [];
    for (let i = 0; i < boardMembers.length; i += 1) {
      if (boardMembers[i].isSignatory === true) {
        const existingPossibleSubject = newPossibleSubjects.find(
          (existing) =>
            existing.socSecurityNumber === boardMembers[i].socSecurityNumber
        );
        if (existingPossibleSubject === undefined) {
          newPossibleSubjects.push({
            id: `possible-subject-${newPossibleSubjects.length}`,
            firstName: boardMembers[i].firstName,
            lastName: boardMembers[i].lastName,
            socSecurityNumber: boardMembers[i].socSecurityNumber,
            email: '',
            idFile: null,
            selected: false,
          });
        }
      }
    }
    for (let i = 0; i < otherSignatories.length; i += 1) {
      const existingPossibleSubject = newPossibleSubjects.find(
        (existing) =>
          existing.socSecurityNumber === otherSignatories[i].socSecurityNumber
      );
      if (existingPossibleSubject === undefined) {
        newPossibleSubjects.push({
          id: `possible-subject-${newPossibleSubjects.length}`,
          firstName: otherSignatories[i].firstName,
          lastName: otherSignatories[i].lastName,
          socSecurityNumber: otherSignatories[i].socSecurityNumber,
          email: '',
          idFile: null,
          selected: false,
        });
      }
    }
    setPossibleSubjects(newPossibleSubjects);
  }, [boardMembers, otherSignatories]);

  const [submissionError, setSubmissionError] = useState('');
  async function SubmitKYCForm() {
    const subjects = [];
    const subjectIdFiles = [];

    for (let i = 0; i < possibleSubjects.length; i += 1) {
      if (possibleSubjects[i].selected === true) {
        subjects.push({
          firstName: possibleSubjects[i].firstName,
          lastName: possibleSubjects[i].lastName,
          email: possibleSubjects[i].email,
          socSecurityNumber: possibleSubjects[i].socSecurityNumber,
        });
        subjectIdFiles.push(possibleSubjects[i].idFile);
      }
    }

    await KYCService.submitKYCForm(
      kycState.clientId,
      kycState.id,
      formType,
      companyName,
      orgNr,
      boardMembers,
      otherSignatories,
      address,
      city,
      zipCode,
      contactPerson,
      settlementEmail,
      phoneNrContactPerson,
      bankAccountType,
      accountNumber,
      webAddress,
      eCommercePlatform,
      incorporationDate,
      nrEmployees,
      estimatedMonthlyRevenueInstitute,
      averageAmountInvoice,
      nrOfTransactionsInstitute,
      businessAbroadDescription,
      coreBusinessDescription,
      businessFinanceDescription,
      loanPurpose,
      businessAbroad ? foreignTransactionAmount : 0,
      businessAbroad ? nrOfForeignTransactions : 0,
      beneficialOwnerPersons,
      beneficialOwnerCompanies,
      companyHierarchyDescription,
      otherInfluentialOwnersPresent,
      otherInfluentialOwnerPersons,
      otherInfluentialOwnerCompanies,
      detailedLoanPurpose,
      detailedBusinessDescription,
      futurePlans,
      estimatedRevenueThreeMonths,
      estimatedRevenueSixMonths,
      estimatedRevenueTwelveMonths,
      estimatedResultThreeMonths,
      estimatedResultSixMonths,
      estimatedResultTwelveMonths,
      sellingService,
      sellingProduct,
      otherRevenueSource,
      serviceDescription,
      productDescription,
      otherRevenueSourceDescription,
      operationsOutsideEUEES,
      operationsOutsideEUEESDescription,
      currencyExchange,
      cryptoCurrencyExchange,
      revenueMonthly,
      handlingCash,
      handlingCash ? cashMonthly : 0,
      nrOfExtraRepayments,
      nrOfWithdrawals,
      companyHierarchyFile,
      accountProofFile,
      subjects,
      subjectIdFiles
    )
      .then(() => {
        setFormStep(maxSteps + 1);
      })
      .catch(() => {
        setSubmissionError('Det gick inte att skicka in formuläret.');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const [errorArray, setErrorArray] = useState([]);
  const requiredErrorText = 'Fältet kan inte vara tomt';
  const orgNrErrorText = 'Måste innehålla 10 siffror';
  const zipCodeErrorText = 'Måste innehålla 5 siffror';
  const ssnErrorText = 'Fyll i 12-siffrigt personnummer';
  const emailErrorText = 'Ej giltig email';
  const fileRequiredErrorText = 'Fil måste laddas upp';

  function GetErrorById(id) {
    const errorText = errorArray.find((error) => error.id === id);
    return errorText !== undefined ? errorText.text : '';
  }

  function DeleteErrorsIncluding(id) {
    setErrorArray(
      errorArray.filter((error) => {
        return !error.id.includes(id);
      })
    );
  }

  function DeleteErrorById(id) {
    setErrorArray(
      errorArray.filter((error) => {
        return error.id !== id;
      })
    );
  }

  function SetErrorById(id, text) {
    const existing = errorArray.find((error, index) => {
      if (error.id === id) {
        const newArray = [...errorArray];
        newArray[index] = { id, text };
        setErrorArray(newArray);

        return error;
      }
      return undefined;
    });
    if (existing === undefined) {
      setErrorArray([
        ...errorArray,
        {
          id,
          text,
        },
      ]);
    }
  }

  function ValidateRequired(input, id) {
    if (input.length === 0) {
      SetErrorById(id, requiredErrorText);
      return false;
    }
    DeleteErrorById(id);
    return true;
  }

  function ValidateZipCode(input, id) {
    if (!ValidateRequired(input, id)) return false;

    if (!Regex.testZipCode(input)) {
      SetErrorById(id, zipCodeErrorText);
      return false;
    }
    return true;
  }

  function ValidateOrgNr(input, id) {
    if (!ValidateRequired(input, id)) return false;

    if (!Regex.testOrgNr(input)) {
      SetErrorById(id, orgNrErrorText);
      return false;
    }
    return true;
  }

  function ValidateSSN(input, id) {
    if (!ValidateRequired(input, id)) return false;

    if (!Regex.testSSN(input)) {
      SetErrorById(id, ssnErrorText);
      return false;
    }
    return true;
  }

  function ValidateEmail(input, id) {
    if (!ValidateRequired(input, id)) return false;

    if (!Regex.testEmail(input)) {
      SetErrorById(id, emailErrorText);
      return false;
    }
    return true;
  }

  function ValidateStep1() {
    const newErrorArray = [];

    if (companyName.length === 0)
      newErrorArray.push({ id: 'companyName', text: requiredErrorText });

    if (orgNr.length === 0) {
      newErrorArray.push({ id: 'orgNr', text: requiredErrorText });
    } else if (!Regex.testOrgNr(orgNr)) {
      newErrorArray.push({ id: 'orgNr', text: orgNrErrorText });
    }

    if (address.length === 0)
      newErrorArray.push({ id: 'address', text: requiredErrorText });

    if (city.length === 0)
      newErrorArray.push({ id: 'city', text: requiredErrorText });

    if (zipCode.length === 0)
      newErrorArray.push({ id: 'zipCode', text: requiredErrorText });

    if (incorporationDate.length === 0)
      newErrorArray.push({ id: 'incorporationDate', text: requiredErrorText });

    if (nrEmployees.length === 0)
      newErrorArray.push({ id: 'nrEmployees', text: requiredErrorText });

    if (accountNumber.length === 0)
      newErrorArray.push({ id: 'accountNumber', text: requiredErrorText });

    if (accountProofFile === null)
      newErrorArray.push({
        id: 'accountProofFile',
        text: fileRequiredErrorText,
      });

    if (contactPerson.length === 0)
      newErrorArray.push({ id: 'contactPerson', text: requiredErrorText });

    if (phoneNrContactPerson.length === 0)
      newErrorArray.push({
        id: 'phoneNrContactPerson',
        text: requiredErrorText,
      });

    if (formType === 2 && settlementEmail.length === 0)
      newErrorArray.push({ id: 'settlementEmail', text: requiredErrorText });

    for (let i = 0; i < boardMembers.length; i += 1) {
      if (boardMembers[i].firstName.length === 0) {
        newErrorArray.push({
          id: `${boardMembers[i].id}-first-name`,
          text: requiredErrorText,
        });
      }
      if (boardMembers[i].lastName.length === 0) {
        newErrorArray.push({
          id: `${boardMembers[i].id}-last-name`,
          text: requiredErrorText,
        });
      }
      if (boardMembers[i].socSecurityNumber.length === 0) {
        newErrorArray.push({
          id: `${boardMembers[i].id}-ssn`,
          text: requiredErrorText,
        });
      } else if (!Regex.testSSN(boardMembers[i].socSecurityNumber)) {
        newErrorArray.push({
          id: `${boardMembers[i].id}-ssn`,
          text: ssnErrorText,
        });
      }
    }

    if (otherSignatoriesExist) {
      for (let i = 0; i < otherSignatories.length; i += 1) {
        if (otherSignatories[i].firstName.length === 0) {
          newErrorArray.push({
            id: `${otherSignatories[i].id}-first-name`,
            text: requiredErrorText,
          });
        }
        if (otherSignatories[i].lastName.length === 0) {
          newErrorArray.push({
            id: `${otherSignatories[i].id}-last-name`,
            text: requiredErrorText,
          });
        }
        if (otherSignatories[i].socSecurityNumber.length === 0) {
          newErrorArray.push({
            id: `${otherSignatories[i].id}-ssn`,
            text: requiredErrorText,
          });
        } else if (!Regex.testSSN(otherSignatories[i].socSecurityNumber)) {
          newErrorArray.push({
            id: `${otherSignatories[i].id}-ssn`,
            text: ssnErrorText,
          });
        }
      }
    }

    setErrorArray(newErrorArray);
    if (newErrorArray.length > 0) {
      return false;
    }

    return true;
  }

  function ValidateStep2() {
    const newErrorArray = [];

    if (companyHierarchyDescription.length === 0) {
      newErrorArray.push({
        id: 'companyHierarchyDescription',
        text: requiredErrorText,
      });
    }

    if (coreBusinessDescription.length === 0) {
      newErrorArray.push({
        id: 'coreBusinessDescription',
        text: requiredErrorText,
      });
    }

    if (detailedBusinessDescription.length === 0) {
      newErrorArray.push({
        id: 'detailedBusinessDescription',
        text: requiredErrorText,
      });
    }

    if (businessFinanceDescription.length === 0) {
      newErrorArray.push({
        id: 'businessFinanceDescription',
        text: requiredErrorText,
      });
    }

    if (businessAbroad && businessAbroadDescription.length === 0) {
      newErrorArray.push({
        id: 'businessAbroadDescription',
        text: requiredErrorText,
      });
    }

    if (
      operationsOutsideEUEES &&
      operationsOutsideEUEESDescription.length === 0
    ) {
      newErrorArray.push({
        id: 'operationsOutsideEUEESDescription',
        text: requiredErrorText,
      });
    }

    for (let i = 0; i < beneficialOwnerPersons.length; i += 1) {
      if (beneficialOwnerPersons[i].firstName.length === 0) {
        newErrorArray.push({
          id: `${beneficialOwnerPersons[i].id}-first-name`,
          text: requiredErrorText,
        });
      }
      if (beneficialOwnerPersons[i].lastName.length === 0) {
        newErrorArray.push({
          id: `${beneficialOwnerPersons[i].id}-last-name`,
          text: requiredErrorText,
        });
      }
      if (beneficialOwnerPersons[i].socSecurityNumber.length === 0) {
        newErrorArray.push({
          id: `${beneficialOwnerPersons[i].id}-ssn`,
          text: requiredErrorText,
        });
      } else if (!Regex.testSSN(beneficialOwnerPersons[i].socSecurityNumber)) {
        newErrorArray.push({
          id: `${beneficialOwnerPersons[i].id}-ssn`,
          text: ssnErrorText,
        });
      }
      if (beneficialOwnerPersons[i].shares.length === 0) {
        newErrorArray.push({
          id: `${beneficialOwnerPersons[i].id}-shares`,
          text: requiredErrorText,
        });
      }
    }

    for (let i = 0; i < beneficialOwnerCompanies.length; i += 1) {
      if (beneficialOwnerCompanies[i].name.length === 0) {
        newErrorArray.push({
          id: `${beneficialOwnerCompanies[i].id}-name`,
          text: requiredErrorText,
        });
      }
      if (beneficialOwnerCompanies[i].orgNumber.length === 0) {
        newErrorArray.push({
          id: `${beneficialOwnerCompanies[i].id}-orgNumber`,
          text: requiredErrorText,
        });
      } else if (!Regex.testOrgNr(beneficialOwnerCompanies[i].orgNumber)) {
        newErrorArray.push({
          id: `${beneficialOwnerCompanies[i].id}-orgNumber`,
          text: ssnErrorText,
        });
      }
      if (beneficialOwnerCompanies[i].shares.length === 0) {
        newErrorArray.push({
          id: `${beneficialOwnerCompanies[i].id}-shares`,
          text: requiredErrorText,
        });
      }
    }

    if (otherInfluentialOwnersPresent) {
      for (let i = 0; i < otherInfluentialOwnerPersons.length; i += 1) {
        if (otherInfluentialOwnerPersons[i].firstName.length === 0) {
          newErrorArray.push({
            id: `${otherInfluentialOwnerPersons[i].id}-first-name`,
            text: requiredErrorText,
          });
        }
        if (otherInfluentialOwnerPersons[i].lastName.length === 0) {
          newErrorArray.push({
            id: `${otherInfluentialOwnerPersons[i].id}-last-name`,
            text: requiredErrorText,
          });
        }
        if (otherInfluentialOwnerPersons[i].socSecurityNumber.length === 0) {
          newErrorArray.push({
            id: `${otherInfluentialOwnerPersons[i].id}-ssn`,
            text: requiredErrorText,
          });
        } else if (
          !Regex.testSSN(otherInfluentialOwnerPersons[i].socSecurityNumber)
        ) {
          newErrorArray.push({
            id: `${otherInfluentialOwnerPersons[i].id}-ssn`,
            text: ssnErrorText,
          });
        }
        if (
          otherInfluentialOwnerPersons[i].descriptionOfInfluenceInCompany
            .length === 0
        ) {
          newErrorArray.push({
            id: `${otherInfluentialOwnerPersons[i].id}-description`,
            text: requiredErrorText,
          });
        }
      }

      for (let i = 0; i < otherInfluentialOwnerCompanies.length; i += 1) {
        if (otherInfluentialOwnerCompanies[i].name.length === 0) {
          newErrorArray.push({
            id: `${otherInfluentialOwnerCompanies[i].id}-name`,
            text: requiredErrorText,
          });
        }
        if (otherInfluentialOwnerCompanies[i].orgNumber.length === 0) {
          newErrorArray.push({
            id: `${otherInfluentialOwnerCompanies[i].id}-orgNumber`,
            text: requiredErrorText,
          });
        } else if (
          !Regex.testOrgNr(otherInfluentialOwnerCompanies[i].orgNumber)
        ) {
          newErrorArray.push({
            id: `${otherInfluentialOwnerCompanies[i].id}-orgNumber`,
            text: ssnErrorText,
          });
        }
        if (
          otherInfluentialOwnerCompanies[i].descriptionOfInfluenceInCompany
            .length === 0
        ) {
          newErrorArray.push({
            id: `${otherInfluentialOwnerCompanies[i].id}-description`,
            text: requiredErrorText,
          });
        }
      }
    }

    setErrorArray(newErrorArray);
    if (newErrorArray.length > 0) {
      return false;
    }
    return true;
  }

  function ValidateStep3() {
    const newErrorArray = [];

    if (loanPurpose.length === 0) {
      newErrorArray.push({
        id: 'loanPurpose',
        text: requiredErrorText,
      });
    }

    if (formType === '0' && detailedLoanPurpose.length === 0) {
      newErrorArray.push({
        id: 'detailedLoanPurpose',
        text: requiredErrorText,
      });
    }

    if (futurePlans.length === 0) {
      newErrorArray.push({
        id: 'futurePlans',
        text: requiredErrorText,
      });
    }

    if (estimatedRevenueThreeMonths.length === 0) {
      newErrorArray.push({
        id: 'estimatedRevenueThreeMonths',
        text: requiredErrorText,
      });
    }

    if (estimatedRevenueSixMonths.length === 0) {
      newErrorArray.push({
        id: 'estimatedRevenueSixMonths',
        text: requiredErrorText,
      });
    }

    if (estimatedRevenueTwelveMonths.length === 0) {
      newErrorArray.push({
        id: 'estimatedRevenueTwelveMonths',
        text: requiredErrorText,
      });
    }

    if (estimatedResultThreeMonths.length === 0) {
      newErrorArray.push({
        id: 'estimatedResultThreeMonths',
        text: requiredErrorText,
      });
    }

    if (estimatedResultSixMonths.length === 0) {
      newErrorArray.push({
        id: 'estimatedResultSixMonths',
        text: requiredErrorText,
      });
    }

    if (estimatedResultTwelveMonths.length === 0) {
      newErrorArray.push({
        id: 'estimatedResultTwelveMonths',
        text: requiredErrorText,
      });
    }

    if (revenueMonthly.length === 0) {
      newErrorArray.push({
        id: 'revenueMonthly',
        text: requiredErrorText,
      });
    }

    if (sellingProduct && productDescription.length === 0) {
      newErrorArray.push({
        id: 'productDescription',
        text: requiredErrorText,
      });
    }

    if (sellingService && serviceDescription.length === 0) {
      newErrorArray.push({
        id: 'serviceDescription',
        text: requiredErrorText,
      });
    }

    if (otherRevenueSource && otherRevenueSourceDescription.length === 0) {
      newErrorArray.push({
        id: 'otherRevenueSourceDescription',
        text: requiredErrorText,
      });
    }

    if (formType === '2') {
      if (estimatedMonthlyRevenueInstitute.length === 0) {
        newErrorArray.push({
          id: 'estimatedMonthlyRevenueInstitute',
          text: requiredErrorText,
        });
      }

      if (nrOfTransactionsInstitute.length === 0) {
        newErrorArray.push({
          id: 'nrOfTransactionsInstitute',
          text: requiredErrorText,
        });
      }

      if (averageAmountInvoice.length === 0) {
        newErrorArray.push({
          id: 'averageAmountInvoice',
          text: requiredErrorText,
        });
      }
    }

    setErrorArray(newErrorArray);
    if (newErrorArray.length > 0) {
      return false;
    }
    return true;
  }

  function ValidateStep4() {
    const newErrorArray = [];

    if (handlingCash && cashMonthly.length === 0) {
      newErrorArray.push({
        id: 'cashMonthly',
        text: requiredErrorText,
      });
    }

    if (nrOfWithdrawals.length === 0) {
      newErrorArray.push({
        id: 'nrOfWithdrawals',
        text: requiredErrorText,
      });
    }

    setErrorArray(newErrorArray);
    if (newErrorArray.length > 0) {
      return false;
    }
    return true;
  }

  function ValidateStep5() {
    const newErrorArray = [];

    const selectedSubjects = possibleSubjects.filter(
      (subject) => subject.selected === true
    );
    if (selectedSubjects.length === 0) {
      return false;
    }

    for (let i = 0; i < selectedSubjects.length; i += 1) {
      if (selectedSubjects[i].idFile === null) {
        newErrorArray.push({
          id: `${selectedSubjects[i].id}-file`,
          text: fileRequiredErrorText,
        });
      }
      if (selectedSubjects[i].email.length === 0) {
        newErrorArray.push({
          id: `${selectedSubjects[i].id}-email`,
          text: requiredErrorText,
        });
      } else if (!Regex.testEmail(selectedSubjects[i].email)) {
        newErrorArray.push({
          id: `${selectedSubjects[i].id}-email`,
          text: emailErrorText,
        });
      }
    }

    setErrorArray(newErrorArray);
    if (newErrorArray.length > 0) {
      return false;
    }
    return true;
  }

  function ScrollToTop() {
    const element = stepRef.current;
    const offset = 20; // Adjust the offset value as needed
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  function GoPrevious() {
    if (formStep > 1) {
      setFormStep(formStep - 1);
      ScrollToTop();
    }
  }

  function OnNext() {
    setFormStep(formStep + 1);
    setSubmissionError('');
    ScrollToTop();
  }

  function GoNext() {
    setErrorArray([]);
    if (formStep === 1) {
      if (ValidateStep1()) {
        OnNext();
      } else {
        setSubmissionError('Det finns fel i formuläret');
      }
    } else if (formStep === 2) {
      if (ValidateStep2()) {
        OnNext();
      } else {
        setSubmissionError('Det finns fel i formuläret');
      }
    } else if (formStep === 3) {
      if (ValidateStep3()) {
        OnNext();
      } else {
        setSubmissionError('Det finns fel i formuläret');
      }
    } else if (formStep === 4) {
      if (ValidateStep4()) {
        OnNext();
      } else {
        setSubmissionError('Det finns fel i formuläret');
      }
    } else if (formStep === maxSteps) {
      if (ValidateStep5()) {
        setLoading(true);
        setSubmissionError('');
        SubmitKYCForm();
      } else {
        setSubmissionError('Det finns fel i formuläret');
      }
    }
  }

  return (
    <div className="kyc-form-wrapper">
      <div className="kyc-modal">
        {loading ? (
          <LoadingBar status="Vänligen vänta..." active />
        ) : kycState === null ? (
          <p className="unauthorized-text">Ej giltig länk</p>
        ) : (
          <>
            <div className="kyc-header">
              <div className="title-row">
                <h1 className="kyc-title">{pageTitle}</h1>
                {kycState.customerLogo ? (
                  <img
                    className="logo"
                    src={kycState.customerLogo}
                    alt="Logo"
                  />
                ) : (
                  ''
                )}
              </div>
              <p className="normal-text">
                Enligt lagen om penningtvätt och finansiering av terrorism
                behöver vi ha god kännedom om dig som kund för att kunna
                fortsätta erbjuda dig våra tjänster och produkter. Frågorna är
                obligatoriska och vi ställer dem till alla våra kunder.
                <br />
                <br />
                {`Har du frågor kring din legitimering, kontakta ${
                  kycState.supportEmail ?? 'info@xpektor.se'
                }.
             Behöver du hjälp eller vägledning? Du är välkommen att kontakta oss på
              ${
                kycState.supportPhoneNumber ?? '0769-424744'
              } om du har frågor. Verifiering sker via Sajnat, som verifierar
               identitet och information online med BankID.`}
              </p>
              {formStep < maxSteps + 1 ? (
                <div className="step-display" ref={stepRef}>
                  <p>{`Steg ${formStep}/${maxSteps}`}</p>
                  <div className="step-lights">
                    <div
                      className={`step-light ${
                        formStep === 1 ? 'active-light' : ''
                      }`}
                    />
                    <div
                      className={`step-light ${
                        formStep === 2 ? 'active-light' : ''
                      }`}
                    />
                    <div
                      className={`step-light ${
                        formStep === 3 ? 'active-light' : ''
                      }`}
                    />
                    <div
                      className={`step-light ${
                        formStep === 4 ? 'active-light' : ''
                      }`}
                    />
                    <div
                      className={`step-light ${
                        formStep === 5 ? 'active-light' : ''
                      }`}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="kyc-content">
              {formStep === 1 ? (
                <ClassicForm>
                  <div className="form-section">
                    <FormDropdown
                      label="Vad avser formuläret?"
                      onChange={(e) => setFormType(e.target.value)}
                      defaultValue={0}
                    >
                      <option value={0}>Lån</option>
                      <option value={1}>Företagskredit</option>
                      <option value={2}>Factoring</option>
                      <option value={3}>Orderfinansiering</option>
                      <option value={4}>Leasing</option>
                    </FormDropdown>
                  </div>
                  <div className="form-section">
                    <div className="form-grid">
                      <FormInput
                        label="Bolagsnamn *"
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                          ValidateRequired(e.target.value, 'companyName');
                        }}
                        infoText={GetErrorById('companyName')}
                        maxLength={100}
                      />
                      <FormInput
                        label="Organisationsnummer *"
                        value={orgNr}
                        onChange={(e) => {
                          setOrgNr(e.target.value);
                          ValidateOrgNr(e.target.value, 'orgNr');
                        }}
                        infoText={GetErrorById('orgNr')}
                        maxLength={100}
                      />
                      <FormInput
                        label="Adress *"
                        value={address}
                        onChange={(e) => {
                          setAddress(e.target.value);
                          ValidateRequired(e.target.value, 'address');
                        }}
                        infoText={GetErrorById('address')}
                        maxLength={100}
                      />
                      <FormInput
                        label="Postort *"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                          ValidateRequired(e.target.value, 'city');
                        }}
                        infoText={GetErrorById('city')}
                        maxLength={100}
                      />
                      <FormInput
                        label="Postnummer *"
                        value={zipCode}
                        onChange={(e) => {
                          setZipCode(e.target.value);
                          ValidateZipCode(e.target.value, 'zipCode');
                        }}
                        infoText={GetErrorById('zipCode')}
                        maxLength={100}
                      />
                      <FormDateInput
                        label="Bolagets startdatum"
                        value={incorporationDate}
                        onChange={(e) => {
                          setIncorporationDate(e.target.value);
                          ValidateRequired(e.target.value, 'incorporationDate');
                        }}
                        infoText={GetErrorById('incorporationDate')}
                      />
                      <FormInput
                        label="Antal anställda *"
                        value={nrEmployees}
                        regex={Regex.testIntegerInput}
                        onChange={(e) => {
                          setNrEmployees(e.target.value);
                          ValidateRequired(e.target.value, 'nrEmployees');
                        }}
                        infoText={GetErrorById('nrEmployees')}
                      />
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-column">
                      <div className="form-row">
                        <FormInput
                          label="Kontonummer *"
                          value={accountNumber}
                          onChange={(e) => {
                            setAccountNumber(e.target.value);
                            ValidateRequired(e.target.value, 'accountNumber');
                          }}
                          infoText={GetErrorById('accountNumber')}
                        />
                        <div className="form-row">
                          <FormDropdown
                            label="Kontotyp"
                            onChange={(e) => setBankAccountType(e.target.value)}
                            defaultValue={1}
                          >
                            <option value={1}>Bankgiro</option>
                            <option value={2}>Plusgiro</option>
                            <option value={3}>Bankkonto</option>
                            <option value={4}>IBAN</option>
                          </FormDropdown>
                          <div />
                        </div>
                      </div>
                      <div className="form-row">
                        <FormFileUpload
                          label="Bifoga kontobevis *"
                          onChange={(e) => {
                            if (e.target.files.length > 0) {
                              setAccountProofFile(e.target.files[0]);
                              DeleteErrorById('accountProofFile');
                            } else {
                              setAccountProofFile(null);
                              SetErrorById('accountProofFile');
                            }
                          }}
                          infoText={GetErrorById('accountProofFile')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="small-title">Styrelsemedlemmar</p>
                      {boardMembers.map((boardMember, index) => {
                        return (
                          <div className="list-entry" key={boardMember.id}>
                            <div className="list-row">
                              <div className="tight-form-row">
                                <FormInput
                                  label="Förnamn *"
                                  value={boardMember.firstName}
                                  onChange={(e) => {
                                    const newArray = [...boardMembers];
                                    newArray[index].firstName = e.target.value;
                                    setBoardMembers(newArray);
                                    ValidateRequired(
                                      e.target.value,
                                      `${boardMember.id}-first-name`
                                    );
                                  }}
                                  infoText={GetErrorById(
                                    `${boardMember.id}-first-name`
                                  )}
                                />
                                <FormInput
                                  label="Efternamn *"
                                  value={boardMember.lastName}
                                  onChange={(e) => {
                                    const newArray = [...boardMembers];
                                    newArray[index].lastName = e.target.value;
                                    setBoardMembers(newArray);
                                    ValidateRequired(
                                      e.target.value,
                                      `${boardMember.id}-last-name`
                                    );
                                  }}
                                  infoText={GetErrorById(
                                    `${boardMember.id}-last-name`
                                  )}
                                />
                                <FormInput
                                  label="Personnummer *"
                                  value={boardMember.socSecurityNumber}
                                  onChange={(e) => {
                                    const newArray = [...boardMembers];
                                    newArray[index].socSecurityNumber =
                                      e.target.value;
                                    setBoardMembers(newArray);
                                    ValidateSSN(
                                      e.target.value,
                                      `${boardMember.id}-ssn`
                                    );
                                  }}
                                  infoText={GetErrorById(
                                    `${boardMember.id}-ssn`
                                  )}
                                />
                                <FormDropdown
                                  label="Är firmatecknare?"
                                  onChange={(e) => {
                                    const newArray = [...boardMembers];
                                    newArray[index].isSignatory =
                                      e.target.value === 'Ja';
                                    setBoardMembers(newArray);
                                  }}
                                  defaultValue
                                >
                                  <option>Ja</option>
                                  <option>Nej</option>
                                </FormDropdown>
                              </div>
                              <button
                                type="button"
                                className="modify-button remove-button"
                                disabled={index === 0}
                                onClick={() => {
                                  DeleteErrorsIncluding(boardMember.id);
                                  setBoardMembers(
                                    boardMembers.filter((filterBoardMember) => {
                                      return filterBoardMember !== boardMember;
                                    })
                                  );
                                }}
                              >
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={faMinus}
                                />
                                <p>Ta bort</p>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                      <button
                        className="modify-button"
                        type="button"
                        onClick={() => AddBoardMemberEntry()}
                      >
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                        <p>Lägg till person</p>
                      </button>
                    </div>
                    <div className="form-column">
                      <div className="radio-select">
                        <p className="small-title">
                          Finns det andra särskilda firmatecknare som inte är
                          del av styrelsen?
                        </p>
                        <div className="choice-row">
                          <RadioButton
                            text="Ja"
                            active={otherSignatoriesExist}
                            onClick={() => setOtherSignatoriesExist(true)}
                          />
                          <RadioButton
                            text="Nej"
                            active={!otherSignatoriesExist}
                            onClick={() => {
                              setOtherSignatoriesExist(false);
                              setOtherSignatories([]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {otherSignatoriesExist ? (
                      <div className="form-column">
                        <p className="small-title">Andra firmatecknare</p>
                        {otherSignatories.map((signatory, index) => {
                          return (
                            <div className="list-entry" key={signatory.id}>
                              <div className="list-row">
                                <div className="tight-form-row">
                                  <FormInput
                                    label="Förnamn *"
                                    value={signatory.firstName}
                                    onChange={(e) => {
                                      const newArray = [...otherSignatories];
                                      newArray[index].firstName =
                                        e.target.value;
                                      setOtherSignatories(newArray);
                                      ValidateRequired(
                                        e.target.value,
                                        `${signatory.id}-first-name`
                                      );
                                    }}
                                    infoText={GetErrorById(
                                      `${signatory.id}-first-name`
                                    )}
                                  />
                                  <FormInput
                                    label="Efternamn *"
                                    value={signatory.lastName}
                                    onChange={(e) => {
                                      const newArray = [...otherSignatories];
                                      newArray[index].lastName = e.target.value;
                                      setOtherSignatories(newArray);
                                      ValidateRequired(
                                        e.target.value,
                                        `${signatory.id}-last-name`
                                      );
                                    }}
                                    infoText={GetErrorById(
                                      `${signatory.id}-last-name`
                                    )}
                                  />
                                  <FormInput
                                    label="Personnummer *"
                                    value={signatory.socSecurityNumber}
                                    onChange={(e) => {
                                      const newArray = [...otherSignatories];
                                      newArray[index].socSecurityNumber =
                                        e.target.value;
                                      setOtherSignatories(newArray);
                                      ValidateSSN(
                                        e.target.value,
                                        `${signatory.id}-ssn`
                                      );
                                    }}
                                    infoText={GetErrorById(
                                      `${signatory.id}-ssn`
                                    )}
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="modify-button remove-button"
                                  onClick={() => {
                                    DeleteErrorsIncluding(signatory.id);
                                    setOtherSignatories(
                                      otherSignatories.filter(
                                        (filterSignatory) => {
                                          return filterSignatory !== signatory;
                                        }
                                      )
                                    );
                                  }}
                                >
                                  <FontAwesomeIcon
                                    className="icon"
                                    icon={faMinus}
                                  />
                                  <p>Ta bort</p>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                        <button
                          className="modify-button"
                          type="button"
                          onClick={() => AddOtherSignatoryEntry()}
                        >
                          <FontAwesomeIcon className="icon" icon={faPlus} />
                          <p>Lägg till person</p>
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-section">
                    <div className="form-grid">
                      <FormInput
                        label="Kontaktperson (Fullständigt namn) *"
                        value={contactPerson}
                        onChange={(e) => {
                          setContactPerson(e.target.value);
                          ValidateRequired(e.target.value, 'contactPerson');
                        }}
                        infoText={GetErrorById('contactPerson')}
                      />
                      <FormInput
                        label="Telefonnummer till kontaktperson *"
                        value={phoneNrContactPerson}
                        regex={Regex.testPhoneNrInput}
                        onChange={(e) => {
                          setPhoneNrContactPerson(e.target.value);
                          ValidateRequired(
                            e.target.value,
                            'phoneNrContactPerson'
                          );
                        }}
                        infoText={GetErrorById('phoneNrContactPerson')}
                      />
                      <FormInput
                        label="Webbadress"
                        value={webAddress}
                        onChange={(e) => setWebAddress(e.target.value)}
                        infoText={GetErrorById('webAddress')}
                      />
                      <FormInput
                        label="E-handelsplattform"
                        value={eCommercePlatform}
                        onChange={(e) => setECommercePlatform(e.target.value)}
                        infoText={GetErrorById('eCommercePlatform')}
                      />
                      {formType === '2' ? (
                        <FormInput
                          label="Email avräkningar *"
                          value={settlementEmail}
                          onChange={(e) => {
                            setSettlementEmail(e.target.value);
                            ValidateRequired(e.target.value, 'settlementEmail');
                          }}
                          infoText={GetErrorById('settlementEmail')}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </ClassicForm>
              ) : formStep === 2 ? (
                <ClassicForm>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="medium-title">Verklig huvudman</p>
                      <p className="normal-text">
                        Ange de fysiska personer eller bolag som direkt, eller
                        indirekt, äger mer än 25% av bolaget. <br /> <br />
                        Vi behöver också veta om personen i fråga har en
                        politiskt utsatt ställning (PEP) eller är en
                        familjemedlem eller arbetar mycket nära en person i
                        politisk utsatt ställning (RCA).
                      </p>
                      <p className="small-title">Personer</p>
                      {beneficialOwnerPersons.length > 0
                        ? beneficialOwnerPersons.map(
                            (beneficialOwner, index) => {
                              return (
                                <div
                                  className="list-entry"
                                  key={beneficialOwner.id}
                                >
                                  <div className="list-row">
                                    <div className="tight-form-row">
                                      <FormInput
                                        label="Förnamn *"
                                        value={beneficialOwner.firstName}
                                        onChange={(e) => {
                                          const newArray = [
                                            ...beneficialOwnerPersons,
                                          ];
                                          newArray[index].firstName =
                                            e.target.value;
                                          setBeneficialOwnerPersons(newArray);
                                          ValidateRequired(
                                            e.target.value,
                                            `${beneficialOwner.id}-first-name`
                                          );
                                        }}
                                        infoText={GetErrorById(
                                          `${beneficialOwner.id}-first-name`
                                        )}
                                      />
                                      <FormInput
                                        label="Efternamn *"
                                        value={beneficialOwner.lastName}
                                        onChange={(e) => {
                                          const newArray = [
                                            ...beneficialOwnerPersons,
                                          ];
                                          newArray[index].lastName =
                                            e.target.value;
                                          setBeneficialOwnerPersons(newArray);
                                          ValidateRequired(
                                            e.target.value,
                                            `${beneficialOwner.id}-last-name`
                                          );
                                        }}
                                        infoText={GetErrorById(
                                          `${beneficialOwner.id}-last-name`
                                        )}
                                      />
                                      <FormInput
                                        label="Personnummer *"
                                        value={
                                          beneficialOwner.socSecurityNumber
                                        }
                                        onChange={(e) => {
                                          const newArray = [
                                            ...beneficialOwnerPersons,
                                          ];
                                          newArray[index].socSecurityNumber =
                                            e.target.value;
                                          setBeneficialOwnerPersons(newArray);
                                          ValidateSSN(
                                            e.target.value,
                                            `${beneficialOwner.id}-ssn`
                                          );
                                        }}
                                        infoText={GetErrorById(
                                          `${beneficialOwner.id}-ssn`
                                        )}
                                      />
                                    </div>
                                    <button
                                      type="button"
                                      className="modify-button remove-button"
                                      onClick={() => {
                                        DeleteErrorsIncluding(
                                          beneficialOwner.id
                                        );
                                        setBeneficialOwnerPersons(
                                          beneficialOwnerPersons.filter(
                                            (filterBeneficialOwner) => {
                                              return (
                                                filterBeneficialOwner !==
                                                beneficialOwner
                                              );
                                            }
                                          )
                                        );
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="icon"
                                        icon={faMinus}
                                      />
                                      <p>Ta bort</p>
                                    </button>
                                  </div>
                                  <div className="list-row">
                                    <div className="tight-form-row">
                                      <div className="tight-form-row">
                                        <FormInput
                                          label="Andel i % *"
                                          value={beneficialOwner.shares}
                                          regex={Regex.testIntegerInput}
                                          onChange={(e) => {
                                            const newArray = [
                                              ...beneficialOwnerPersons,
                                            ];
                                            newArray[index].shares =
                                              e.target.value;
                                            setBeneficialOwnerPersons(newArray);
                                            ValidateRequired(
                                              e.target.value,
                                              `${beneficialOwner.id}-shares`
                                            );
                                          }}
                                          infoText={GetErrorById(
                                            `${beneficialOwner.id}-shares`
                                          )}
                                        />
                                        <FormDropdown
                                          label="PEP eller RCA"
                                          onChange={(e) => {
                                            const newArray = [
                                              ...beneficialOwnerPersons,
                                            ];
                                            newArray[index].PEP =
                                              e.target.value === 'PEP';
                                            newArray[index].RCA =
                                              e.target.value === 'RCA';

                                            setBeneficialOwnerPersons(newArray);
                                          }}
                                          defaultValue="none"
                                        >
                                          <option selected value="none">
                                            Nej
                                          </option>
                                          <option value="PEP">PEP</option>
                                          <option value="RCA">RCA</option>
                                        </FormDropdown>
                                      </div>
                                      <div className="form-row" />
                                      <div className="form-row" />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : ''}
                      <button
                        className="modify-button"
                        type="button"
                        onClick={() => AddBeneficialOwnerPersonEntry()}
                      >
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                        <p>Lägg till person</p>
                      </button>
                    </div>
                    <div className="form-column">
                      <p className="small-title">Bolag</p>
                      {beneficialOwnerCompanies.length > 0
                        ? beneficialOwnerCompanies.map(
                            (beneficialOwner, index) => {
                              return (
                                <div
                                  className="list-entry"
                                  key={beneficialOwner.id}
                                >
                                  <div className="list-row">
                                    <div className="tight-form-row">
                                      <FormInput
                                        label="Bolagets namn *"
                                        value={beneficialOwner.name}
                                        onChange={(e) => {
                                          const newArray = [
                                            ...beneficialOwnerCompanies,
                                          ];
                                          newArray[index].name = e.target.value;
                                          setBeneficialOwnerCompanies(newArray);
                                          ValidateRequired(
                                            e.target.value,
                                            `${beneficialOwner.id}-name`
                                          );
                                        }}
                                        infoText={GetErrorById(
                                          `${beneficialOwner.id}-name`
                                        )}
                                      />
                                      <FormInput
                                        label="Organisationsnummer *"
                                        value={beneficialOwner.orgNumber}
                                        onChange={(e) => {
                                          const newArray = [
                                            ...beneficialOwnerCompanies,
                                          ];
                                          newArray[index].orgNumber =
                                            e.target.value;
                                          setBeneficialOwnerCompanies(newArray);
                                          ValidateOrgNr(
                                            e.target.value,
                                            `${beneficialOwner.id}-orgNumber`
                                          );
                                        }}
                                        infoText={GetErrorById(
                                          `${beneficialOwner.id}-orgNumber`
                                        )}
                                      />
                                      <div className="tight-form-row">
                                        <FormInput
                                          label="Andel i % *"
                                          value={beneficialOwner.shares}
                                          onChange={(e) => {
                                            const newArray = [
                                              ...beneficialOwnerCompanies,
                                            ];
                                            newArray[index].shares =
                                              e.target.value;
                                            setBeneficialOwnerCompanies(
                                              newArray
                                            );
                                            ValidateRequired(
                                              e.target.value,
                                              `${beneficialOwner.id}-shares`
                                            );
                                          }}
                                          infoText={GetErrorById(
                                            `${beneficialOwner.id}-shares`
                                          )}
                                        />
                                        <div className="form-row" />
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="modify-button remove-button"
                                      onClick={() => {
                                        DeleteErrorsIncluding(
                                          beneficialOwner.id
                                        );
                                        setBeneficialOwnerCompanies(
                                          beneficialOwnerCompanies.filter(
                                            (filterBeneficialOwner) => {
                                              return (
                                                filterBeneficialOwner !==
                                                beneficialOwner
                                              );
                                            }
                                          )
                                        );
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        className="icon"
                                        icon={faMinus}
                                      />
                                      <p>Ta bort</p>
                                    </button>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : ''}
                      <button
                        className="modify-button"
                        type="button"
                        onClick={() => AddBeneficialOwnerCompanyEntry()}
                      >
                        <FontAwesomeIcon className="icon" icon={faPlus} />
                        <p>Lägg till bolag</p>
                      </button>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-column">
                      <FormTextArea
                        label="Beskriv bolagets ägarstruktur *"
                        rows={6}
                        value={companyHierarchyDescription}
                        onChange={(e) => {
                          setCompanyHierarchyDescription(e.target.value);
                          ValidateRequired(
                            e.target.value,
                            'companyHierarchyDescription'
                          );
                        }}
                        infoText={GetErrorById('companyHierarchyDescription')}
                      />
                      <FormFileUpload
                        label="Bifoga karta över ägarstruktur om det finns"
                        onChange={(e) => {
                          if (e.target.files) {
                            setCompanyHierarchyFile(e.target.files[0]);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Finns det personer eller bolag som på annat sätt har
                        bestämmande inflytande i företaget?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={otherInfluentialOwnersPresent}
                          onClick={() => setOtherInfluentialOwnersPresent(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!otherInfluentialOwnersPresent}
                          onClick={() =>
                            setOtherInfluentialOwnersPresent(false)
                          }
                        />
                      </div>
                    </div>
                    {otherInfluentialOwnersPresent ? (
                      <>
                        <div className="form-column">
                          <p className="small-title">Personer</p>
                          <div className="form-column">
                            {otherInfluentialOwnerPersons.length > 0
                              ? otherInfluentialOwnerPersons.map(
                                  (influentialPerson, index) => {
                                    return (
                                      <div
                                        key={influentialPerson.id}
                                        className="list-entry"
                                      >
                                        <div className="list-row">
                                          <div className="tight-form-row">
                                            <FormInput
                                              label="Förnamn *"
                                              value={
                                                influentialPerson.firstName
                                              }
                                              onChange={(e) => {
                                                const newArray = [
                                                  ...otherInfluentialOwnerPersons,
                                                ];
                                                newArray[index].firstName =
                                                  e.target.value;
                                                setOtherInfluentialOwnerPersons(
                                                  newArray
                                                );
                                                ValidateRequired(
                                                  e.target.value,
                                                  `${influentialPerson.id}-first-name`
                                                );
                                              }}
                                              infoText={GetErrorById(
                                                `${influentialPerson.id}-first-name`
                                              )}
                                            />
                                            <FormInput
                                              label="Efternamn *"
                                              value={influentialPerson.lastName}
                                              onChange={(e) => {
                                                const newArray = [
                                                  ...otherInfluentialOwnerPersons,
                                                ];
                                                newArray[index].lastName =
                                                  e.target.value;
                                                setOtherInfluentialOwnerPersons(
                                                  newArray
                                                );
                                                ValidateRequired(
                                                  e.target.value,
                                                  `${influentialPerson.id}-last-name`
                                                );
                                              }}
                                              infoText={GetErrorById(
                                                `${influentialPerson.id}-last-name`
                                              )}
                                            />
                                            <FormInput
                                              label="Personnummer *"
                                              value={
                                                influentialPerson.socSecurityNumber
                                              }
                                              onChange={(e) => {
                                                const newArray = [
                                                  ...otherInfluentialOwnerPersons,
                                                ];
                                                newArray[
                                                  index
                                                ].socSecurityNumber =
                                                  e.target.value;
                                                setOtherInfluentialOwnerPersons(
                                                  newArray
                                                );
                                                ValidateSSN(
                                                  e.target.value,
                                                  `${influentialPerson.id}-ssn`
                                                );
                                              }}
                                              infoText={GetErrorById(
                                                `${influentialPerson.id}-ssn`
                                              )}
                                            />
                                            <FormDropdown
                                              label="PEP eller RCA"
                                              onChange={(e) => {
                                                const newArray = [
                                                  ...otherInfluentialOwnerPersons,
                                                ];
                                                newArray[index].PEP =
                                                  e.target.value === 'PEP';
                                                newArray[index].RCA =
                                                  e.target.value === 'RCA';

                                                setOtherInfluentialOwnerPersons(
                                                  newArray
                                                );
                                              }}
                                              defaultValue="none"
                                            >
                                              <option value="none">Nej</option>
                                              <option value="PEP">PEP</option>
                                              <option value="RCA">RCA</option>
                                            </FormDropdown>
                                          </div>
                                          <button
                                            type="button"
                                            className="modify-button remove-button"
                                            onClick={() => {
                                              DeleteErrorsIncluding(
                                                influentialPerson.id
                                              );
                                              setOtherInfluentialOwnerPersons(
                                                otherInfluentialOwnerPersons.filter(
                                                  (filterInfluential) => {
                                                    return (
                                                      filterInfluential !==
                                                      influentialPerson
                                                    );
                                                  }
                                                )
                                              );
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              className="icon"
                                              icon={faMinus}
                                            />
                                            <p>Ta bort</p>
                                          </button>
                                        </div>
                                        <div className="list-row">
                                          <FormTextArea
                                            label="Beskriv inflytande *"
                                            rows={2}
                                            value={
                                              influentialPerson.descriptionOfInfluenceInCompany
                                            }
                                            onChange={(e) => {
                                              const newArray = [
                                                ...otherInfluentialOwnerPersons,
                                              ];
                                              newArray[
                                                index
                                              ].descriptionOfInfluenceInCompany =
                                                e.target.value;
                                              setOtherInfluentialOwnerPersons(
                                                newArray
                                              );
                                              ValidateRequired(
                                                e.target.value,
                                                `${influentialPerson.id}-description`
                                              );
                                            }}
                                            infoText={GetErrorById(
                                              `${influentialPerson.id}-description`
                                            )}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : ''}
                          </div>
                          <button
                            className="modify-button"
                            type="button"
                            onClick={() => AddOtherInfluentialPerson()}
                          >
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                            <p>Lägg till person</p>
                          </button>
                        </div>
                        <div className="form-column">
                          <p className="small-title">Bolag</p>
                          <div className="form-column">
                            {otherInfluentialOwnerCompanies.length > 0
                              ? otherInfluentialOwnerCompanies.map(
                                  (influentialCompany, index) => {
                                    return (
                                      <div
                                        className="list-entry"
                                        key={influentialCompany.id}
                                      >
                                        <div className="list-row">
                                          <div className="tight-form-row">
                                            <FormInput
                                              label="Bolagets namn *"
                                              value={influentialCompany.name}
                                              onChange={(e) => {
                                                const newArray = [
                                                  ...otherInfluentialOwnerCompanies,
                                                ];
                                                newArray[index].name =
                                                  e.target.value;
                                                setOtherInfluentialOwnerCompanies(
                                                  newArray
                                                );
                                                ValidateRequired(
                                                  e.target.value,
                                                  `${influentialCompany.id}-name`
                                                );
                                              }}
                                              infoText={GetErrorById(
                                                `${influentialCompany.id}-name`
                                              )}
                                            />
                                            <FormInput
                                              label="Organisationsnummer *"
                                              value={
                                                influentialCompany.orgNumber
                                              }
                                              onChange={(e) => {
                                                const newArray = [
                                                  ...otherInfluentialOwnerCompanies,
                                                ];
                                                newArray[index].orgNumber =
                                                  e.target.value;
                                                setOtherInfluentialOwnerCompanies(
                                                  newArray
                                                );
                                                ValidateOrgNr(
                                                  e.target.value,
                                                  `${influentialCompany.id}-orgNumber`
                                                );
                                              }}
                                              infoText={GetErrorById(
                                                `${influentialCompany.id}-orgNumber`
                                              )}
                                            />
                                          </div>
                                          <button
                                            type="button"
                                            className="modify-button remove-button"
                                            onClick={() => {
                                              DeleteErrorById(
                                                `${influentialCompany.id}-name`
                                              );
                                              DeleteErrorById(
                                                `${influentialCompany.id}-orgNumber`
                                              );
                                              setOtherInfluentialOwnerCompanies(
                                                otherInfluentialOwnerCompanies.filter(
                                                  (filterInfluential) => {
                                                    return (
                                                      filterInfluential !==
                                                      influentialCompany
                                                    );
                                                  }
                                                )
                                              );
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              className="icon"
                                              icon={faMinus}
                                            />
                                            <p>Ta bort</p>
                                          </button>
                                        </div>
                                        <div className="list-row">
                                          <FormTextArea
                                            rows={2}
                                            label="Beskriv inflytande *"
                                            value={
                                              influentialCompany.descriptionOfInfluenceInCompany
                                            }
                                            onChange={(e) => {
                                              const newArray = [
                                                ...otherInfluentialOwnerCompanies,
                                              ];
                                              newArray[
                                                index
                                              ].descriptionOfInfluenceInCompany =
                                                e.target.value;
                                              setOtherInfluentialOwnerCompanies(
                                                newArray
                                              );
                                              ValidateRequired(
                                                e.target.value,
                                                `${influentialCompany.id}-description`
                                              );
                                            }}
                                            infoText={GetErrorById(
                                              `${influentialCompany.id}-description`
                                            )}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : ''}
                          </div>
                          <button
                            className="modify-button"
                            type="button"
                            onClick={() => AddOtherInfluentialCompany()}
                          >
                            <FontAwesomeIcon className="icon" icon={faPlus} />
                            <p>Lägg till bolag</p>
                          </button>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="medium-title">Verksamhet</p>
                      <FormTextArea
                        label="Vilken verksamhet bedrivs eller kommer att bedrivas i bolaget? *"
                        rows={6}
                        value={coreBusinessDescription}
                        onChange={(e) => {
                          setCoreBusinessDescription(e.target.value);
                          ValidateRequired(
                            e.target.value,
                            'coreBusinessDescription'
                          );
                        }}
                        infoText={GetErrorById('coreBusinessDescription')}
                      />

                      <FormTextArea
                        label="Beskriv detaljerat om din verksamhet. Exempel, vilka typer
                               av kunder ni jobbar med, privat/företag? Vilka leverantörer? *"
                        rows={6}
                        value={detailedBusinessDescription}
                        onChange={(e) => {
                          setDetailedBusinessDescription(e.target.value);
                          ValidateRequired(
                            e.target.value,
                            'detailedBusinessDescription'
                          );
                        }}
                        infoText={GetErrorById('detailedBusinessDescription')}
                      />
                      <FormTextArea
                        label="Hur finansieras verksamheten? (Ägarmedel, lån, upparbetade vinster etc) *"
                        rows={6}
                        value={businessFinanceDescription}
                        onChange={(e) => {
                          setBusinessFinanceDescription(e.target.value);
                          ValidateRequired(
                            e.target.value,
                            'businessFinanceDescription'
                          );
                        }}
                        infoText={GetErrorById('businessFinanceDescription')}
                      />
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Gör ni affärer med utlandet?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={businessAbroad}
                          onClick={() => setBusinessAbroad(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!businessAbroad}
                          onClick={() => setBusinessAbroad(false)}
                        />
                      </div>
                    </div>
                    {businessAbroad ? (
                      <>
                        <FormTextArea
                          label="Beskriv hur ni gör affärer med utlandet samt vilka länder som omfattas *"
                          value={businessAbroadDescription}
                          onChange={(e) => {
                            setBusinessAbroadDescription(e.target.value);
                            ValidateRequired(
                              e.target.value,
                              'businessAbroadDescription'
                            );
                          }}
                          rows={6}
                          infoText={GetErrorById('businessAbroadDescription')}
                        />
                        <div className="form-row">
                          <div className="radio-select">
                            <p className="small-title">
                              Hur stora belopp tror ni kommer komma från
                              utlandet varje månad?
                            </p>
                            <div className="choice-column">
                              <RadioButton
                                text="0 - 100 000"
                                active={foreignTransactionAmount === 10}
                                onClick={() => setForeignTransactionAmount(10)}
                              />
                              <RadioButton
                                text="100 000 - 500 000"
                                active={foreignTransactionAmount === 20}
                                onClick={() => setForeignTransactionAmount(20)}
                              />
                              <RadioButton
                                text="500 000-1 000 000"
                                active={foreignTransactionAmount === 30}
                                onClick={() => setForeignTransactionAmount(30)}
                              />
                              <RadioButton
                                text="Över 1 000 000"
                                active={foreignTransactionAmount === 40}
                                onClick={() => setForeignTransactionAmount(40)}
                              />
                            </div>
                          </div>
                          <div className="radio-select">
                            <p className="small-title">
                              Hur många transaktioner per månad tror ni kommer
                              från utlandet?
                            </p>
                            <div className="choice-column">
                              <RadioButton
                                text="0"
                                active={nrOfForeignTransactions === 0}
                                onClick={() => setNrOfForeignTransactions(0)}
                              />
                              <RadioButton
                                text="1 - 2"
                                active={nrOfForeignTransactions === 1}
                                onClick={() => setNrOfForeignTransactions(1)}
                              />
                              <RadioButton
                                text="3 - 5"
                                active={nrOfForeignTransactions === 2}
                                onClick={() => setNrOfForeignTransactions(2)}
                              />
                              <RadioButton
                                text="6 - 8"
                                active={nrOfForeignTransactions === 3}
                                onClick={() => setNrOfForeignTransactions(3)}
                              />
                              <RadioButton
                                text="9 - 15"
                                active={nrOfForeignTransactions === 4}
                                onClick={() => setNrOfForeignTransactions(4)}
                              />
                              <RadioButton
                                text="Mer än 15"
                                active={nrOfForeignTransactions === 5}
                                onClick={() => setNrOfForeignTransactions(5)}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Bedriver ni verksamhet utanför EU/ESS?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={operationsOutsideEUEES}
                          onClick={() => setOperationsOutsideEUESS(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!operationsOutsideEUEES}
                          onClick={() => setOperationsOutsideEUESS(false)}
                        />
                      </div>
                    </div>
                    {operationsOutsideEUEES ? (
                      <FormTextArea
                        label="Ange i vilka länder *"
                        rows={6}
                        value={operationsOutsideEUEESDescription}
                        onChange={(e) => {
                          setOperationsOutsideEUESSDescription(e.target.value);
                          ValidateRequired(
                            e.target.value,
                            'operationsOutsideEUEESDescription'
                          );
                        }}
                        infoText={GetErrorById(
                          'operationsOutsideEUEESDescription'
                        )}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </ClassicForm>
              ) : formStep === 3 ? (
                <ClassicForm>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="medium-title">Syfte</p>
                      <FormTextArea
                        label="Vad är ert syfte med att nyttja kreditinstitutets betallösning? *"
                        rows={6}
                        value={loanPurpose}
                        onChange={(e) => {
                          setLoanPurpose(e.target.value);
                          ValidateRequired(e.target.value, 'loanPurpose');
                        }}
                        infoText={GetErrorById('loanPurpose')}
                      />
                      {formType === '0' ? (
                        <FormTextArea
                          rows={6}
                          label="Vänligen förklara mer ingående vad lånet är avsett för. Uppge exempel på leverantör som skall betalas och/eller lån som skall re-finansieras. *"
                          value={detailedLoanPurpose}
                          onChange={(e) => {
                            setDetailedLoanPurpose(e.target.value);
                            ValidateRequired(
                              e.target.value,
                              'detailedLoanPurpose'
                            );
                          }}
                          infoText={GetErrorById('detailedLoanPurpose')}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="medium-title">Framtidsplaner</p>
                      <FormTextArea
                        rows={6}
                        label="Vilka är dina framtidsplaner när det gäller förväntad utveckling av exempelvis omsättning, intäkter, kostnader eller resultat? *"
                        value={futurePlans}
                        onChange={(e) => {
                          setFuturePlans(e.target.value);
                          ValidateRequired(e.target.value, 'futurePlans');
                        }}
                        infoText={GetErrorById('futurePlans')}
                      />
                      <div className="form-row">
                        <div className="form-column">
                          <FormInput
                            label="Uppskattad omsättning om 3 månader *"
                            value={estimatedRevenueThreeMonths}
                            regex={Regex.testIntegerInput}
                            onChange={(e) => {
                              setEstimatedRevenueThreeMonths(e.target.value);
                              ValidateRequired(
                                e.target.value,
                                'estimatedRevenueThreeMonths'
                              );
                            }}
                            infoText={GetErrorById(
                              'estimatedRevenueThreeMonths'
                            )}
                          />
                          <FormInput
                            label="Uppskattad omsättning om 6 månader *"
                            value={estimatedRevenueSixMonths}
                            regex={Regex.testIntegerInput}
                            onChange={(e) => {
                              setEstimatedRevenueSixMonths(e.target.value);
                              ValidateRequired(
                                e.target.value,
                                'estimatedRevenueSixMonths'
                              );
                            }}
                            infoText={GetErrorById('estimatedRevenueSixMonths')}
                          />
                          <FormInput
                            label="Uppskattad omsättning om 12 månader *"
                            value={estimatedRevenueTwelveMonths}
                            regex={Regex.testIntegerInput}
                            onChange={(e) => {
                              setEstimatedRevenueTwelveMonths(e.target.value);
                              ValidateRequired(
                                e.target.value,
                                'estimatedRevenueTwelveMonths'
                              );
                            }}
                            infoText={GetErrorById(
                              'estimatedRevenueTwelveMonths'
                            )}
                          />
                        </div>
                        <div className="form-column">
                          <FormInput
                            label="Uppskattat resultat om 3 månader *"
                            value={estimatedResultThreeMonths}
                            regex={Regex.testIntegerInput}
                            onChange={(e) => {
                              setEstimatedResultThreeMonths(e.target.value);
                              ValidateRequired(
                                e.target.value,
                                'estimatedResultThreeMonths'
                              );
                            }}
                            infoText={GetErrorById(
                              'estimatedResultThreeMonths'
                            )}
                          />
                          <FormInput
                            label="Uppskattat resultat om 6 månader *"
                            value={estimatedResultSixMonths}
                            regex={Regex.testIntegerInput}
                            onChange={(e) => {
                              setEstimatedResultSixMonths(e.target.value);
                              ValidateRequired(
                                e.target.value,
                                'estimatedResultSixMonths'
                              );
                            }}
                            infoText={GetErrorById('estimatedResultSixMonths')}
                          />
                          <FormInput
                            label="Uppskattat resultat om 12 månader *"
                            value={estimatedResultTwelveMonths}
                            regex={Regex.testIntegerInput}
                            onChange={(e) => {
                              setEstimatedResultTwelveMonths(e.target.value);
                              ValidateRequired(
                                e.target.value,
                                'estimatedResultTwelveMonths'
                              );
                            }}
                            infoText={GetErrorById(
                              'estimatedResultTwelveMonths'
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="medium-title">Månadsomsättning</p>
                      <FormInput
                        label="Hur mycket omsätter företaget varje månad? *"
                        value={revenueMonthly}
                        regex={Regex.testIntegerInput}
                        onChange={(e) => {
                          setRevenueMothly(e.target.value);
                          ValidateRequired(e.target.value, 'revenueMonthly');
                        }}
                        infoText={GetErrorById('revenueMonthly')}
                      />
                      <p className="normal-text">
                        Vänligen specificera vad som ingår i månadsomsättningen.
                        Den kan inkludera försäljning av produkter, tjänster
                        eller andra intäktskällor.
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Produkter"
                          active={sellingProduct}
                          onClick={() => setSellingProduct(!sellingProduct)}
                          checkBox
                        />
                        <RadioButton
                          text="Tjänster"
                          active={sellingService}
                          onClick={() => setSellingService(!sellingService)}
                          checkBox
                        />
                        <RadioButton
                          text="Annat"
                          active={otherRevenueSource}
                          onClick={() =>
                            setOtherRevenueSource(!otherRevenueSource)
                          }
                          checkBox
                        />
                      </div>
                    </div>
                    {sellingProduct ? (
                      <FormTextArea
                        rows={4}
                        label="Förklara produkter *"
                        value={productDescription}
                        onChange={(e) => {
                          setProductDescription(e.target.value);
                          ValidateRequired(
                            e.target.value,
                            'productDescription'
                          );
                        }}
                        infoText={GetErrorById('productDescription')}
                      />
                    ) : (
                      ''
                    )}
                    {sellingService ? (
                      <FormTextArea
                        rows={4}
                        label="Förklara tjänster *"
                        value={serviceDescription}
                        onChange={(e) => {
                          setServiceDescription(e.target.value);
                          ValidateRequired(
                            e.target.value,
                            'serviceDescription'
                          );
                        }}
                        infoText={GetErrorById('serviceDescription')}
                      />
                    ) : (
                      ''
                    )}
                    {otherRevenueSource ? (
                      <FormTextArea
                        rows={4}
                        label="Förklara annat *"
                        value={otherRevenueSourceDescription}
                        onChange={(e) => {
                          setOtherRevenueSourceDescription(e.target.value);
                          ValidateRequired(
                            e.target.value,
                            'otherRevenueSourceDescription'
                          );
                        }}
                        infoText={GetErrorById('otherRevenueSourceDescription')}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  {formType === '2' ? (
                    <div className="form-section">
                      <div className="form-grid">
                        <FormInput
                          label="Estimerad omsättning per mån via kreditinstitut *"
                          value={estimatedMonthlyRevenueInstitute}
                          regex={Regex.testIntegerInput}
                          onChange={(e) => {
                            setEstimatedMonthlyRevenueInstitute(e.target.value);
                            ValidateRequired(
                              e.target.value,
                              'estimatedMonthlyRevenueInstitute'
                            );
                          }}
                          infoText={GetErrorById(
                            'estimatedMonthlyRevenueInstitute'
                          )}
                        />
                        <FormInput
                          label="Antal transaktioner per månad via kreditinstitut *"
                          value={nrOfTransactionsInstitute}
                          regex={Regex.testIntegerInput}
                          onChange={(e) => {
                            setNrOfTransactionsInstitute(e.target.value);
                            ValidateRequired(
                              e.target.value,
                              'nrOfTransactionsInstitute'
                            );
                          }}
                          infoText={GetErrorById('nrOfTransactionsInstitute')}
                        />
                        <FormInput
                          label="Snittbelopp per faktura *"
                          value={averageAmountInvoice}
                          regex={Regex.testIntegerInput}
                          onChange={(e) => {
                            setAverageAmountInvoice(e.target.value);
                            ValidateRequired(
                              e.target.value,
                              'averageAmountInvoice'
                            );
                          }}
                          infoText={GetErrorById('averageAmountInvoice')}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </ClassicForm>
              ) : formStep === 4 ? (
                <ClassicForm>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Bedriver företaget någon verksamhet inom valutaväxling?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={currencyExchange}
                          onClick={() => setCurrencyExchange(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!currencyExchange}
                          onClick={() => setCurrencyExchange(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Är företaget inblandat i omvandling till kryptovaluta?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={cryptoCurrencyExchange}
                          onClick={() => setCryptoCurrencyExchange(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!cryptoCurrencyExchange}
                          onClick={() => setCryptoCurrencyExchange(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">
                        Använder företaget kontanter?
                      </p>
                      <div className="choice-row">
                        <RadioButton
                          text="Ja"
                          active={handlingCash}
                          onClick={() => setHandlingCash(true)}
                        />
                        <RadioButton
                          text="Nej"
                          active={!handlingCash}
                          onClick={() => setHandlingCash(false)}
                        />
                      </div>
                    </div>
                    {handlingCash ? (
                      <FormInput
                        label="Ange belopp i kassa per månad *"
                        value={cashMonthly}
                        regex={Regex.testIntegerInput}
                        onChange={(e) => {
                          setCashMonthly(e.target.value);
                          ValidateRequired(e.target.value, 'cashMonthly');
                        }}
                        infoText={GetErrorById('cashMonthly')}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-section">
                    <div className="radio-select">
                      <p className="small-title">Insättningar</p>
                      <p className="normal-text">
                        {`Har du planer på att göra extra avbetalningar/insättningar
                    varje månad? Om "Ja", hur ofta kommer insättningar ske under
                    månaden?`}
                      </p>
                      <div className="choice-column">
                        <RadioButton
                          text="Nej"
                          active={nrOfExtraRepayments === 0}
                          onClick={() => setNrOfExtraRepayments(0)}
                        />
                        <RadioButton
                          text="1 - 2"
                          active={nrOfExtraRepayments === 1}
                          onClick={() => setNrOfExtraRepayments(1)}
                        />
                        <RadioButton
                          text="2 - 4"
                          active={nrOfExtraRepayments === 2}
                          onClick={() => setNrOfExtraRepayments(2)}
                        />
                        <RadioButton
                          text="5 - 8"
                          active={nrOfExtraRepayments === 3}
                          onClick={() => setNrOfExtraRepayments(3)}
                        />
                        <RadioButton
                          text="Mer än 8"
                          active={nrOfExtraRepayments === 4}
                          onClick={() => setNrOfExtraRepayments(4)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="small-title">Uttag</p>
                      <p className="normal-text">
                        Ange hur många uttag du planerar att göra i månaden.
                        Självklart kan du inte veta säkert så gör en
                        uppskattningvad det kan bli i genomsnitt per månad.
                        (Skriv 0 vid ansökan om ett företagslån)
                      </p>
                      <FormInput
                        label="Antal uttag *"
                        value={nrOfWithdrawals}
                        regex={Regex.testIntegerInput}
                        onChange={(e) => {
                          setNrOfWithdrawals(e.target.value);
                          ValidateRequired(e.target.value, 'nrOfWithdrawals');
                        }}
                        infoText={GetErrorById('nrOfWithdrawals')}
                      />
                    </div>
                  </div>
                </ClassicForm>
              ) : formStep === 5 ? (
                <ClassicForm>
                  <div className="form-section">
                    <div className="form-column">
                      <p className="medium-title">Skicka in</p>
                      <p className="small-title">
                        Vem ska signera det här dokumentet? Du kan välja fler.
                      </p>
                      <div className="form-column">
                        {possibleSubjects.length > 0
                          ? possibleSubjects.map((subject, index) => {
                              return (
                                <div className="subject-row" key={subject.id}>
                                  <RadioButton
                                    text={`${subject.firstName} ${subject.lastName}`}
                                    checkBox
                                    active={subject.selected}
                                    onClick={() => {
                                      const newArray = [...possibleSubjects];
                                      newArray[index].selected =
                                        !subject.selected;
                                      setPossibleSubjects(newArray);
                                    }}
                                  />
                                  {subject.selected ? (
                                    <>
                                      <FormInput
                                        label="Email *"
                                        value={subject.email}
                                        onChange={(e) => {
                                          const newArray = [
                                            ...possibleSubjects,
                                          ];
                                          newArray[index].email =
                                            e.target.value;
                                          setPossibleSubjects(newArray);
                                          ValidateEmail(
                                            e.target.value,
                                            `${subject.id}-email`
                                          );
                                        }}
                                        infoText={GetErrorById(
                                          `${subject.id}-email`
                                        )}
                                      />
                                      <FormFileUpload
                                        label="Ladda upp pass/id *"
                                        onChange={(e) => {
                                          const newArray = [
                                            ...possibleSubjects,
                                          ];
                                          if (e.target.files.length > 0) {
                                            const file = e.target.files[0];
                                            newArray[index].idFile = file;
                                            DeleteErrorById(
                                              `${subject.id}-file`
                                            );
                                          } else {
                                            newArray[index].idFile = null;
                                            SetErrorById(
                                              `${subject.id}-file`,
                                              fileRequiredErrorText
                                            );
                                          }

                                          setPossibleSubjects(newArray);
                                        }}
                                        infoText={GetErrorById(
                                          `${subject.id}-file`
                                        )}
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              );
                            })
                          : ''}
                      </div>
                    </div>
                  </div>
                </ClassicForm>
              ) : formStep === maxSteps + 1 ? (
                <div className="form-submitted-content">
                  <FontAwesomeIcon className="icon" icon={faCheckCircle} />
                  <p className="medium-title">Tack!</p>
                  <p className="normal-text">
                    Uppgifterna har skickats in och du kan nu stänga den här
                    sidan.
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
            {formStep !== maxSteps + 1 ? (
              <div className="nav-footer">
                <div className="info-row">
                  {submissionError ? (
                    <p className="submission-error normal-text">
                      {submissionError}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                <div className="button-row">
                  {formStep !== 1 ? (
                    <XpektorButton
                      content="Föregående steg"
                      onClick={() => GoPrevious()}
                    />
                  ) : (
                    ''
                  )}
                  <div />
                  <XpektorButton
                    content={formStep < maxSteps ? 'Nästa steg' : 'Skicka in'}
                    onClick={() => GoNext()}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </div>
  );
}
