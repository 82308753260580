import '../../Modals/XpektorBootstrapModal.scss';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  ClassicForm,
  FormFileUpload,
  FormButton,
} from '../../../../Components/XpektorForms/XpektorForms';
/* eslint-disable react/prop-types */

export default function ChangeLogoModal({ customer, handleClose }) {
  const [submitting, setSubmitting] = useState(false);

  const validateAndSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      centered
      keyboard={false}
      className="border-0 xpektor-bootstrap-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Ändra logga</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="image-window">
          {customer.logo ? (
            <img src={customer.logo} alt="logo" />
          ) : (
            <p className="missing-text">Logga saknas</p>
          )}
        </div>
        <ClassicForm onSubmit={validateAndSubmit}>
          <FormFileUpload label="Ladda upp fil" />
          <div className="spaced-row">
            <FormButton text="Spara" disabled={submitting} />
          </div>
        </ClassicForm>
      </Modal.Body>
    </Modal>
  );
}
