import { useState, useEffect } from 'react';
import categories from '../../../FAQData';
import XpektorSlider from '../../../Components/XpektorSlider/XpektorSlider';
import Question from './Question';
import IconButton from '../../../Components/IconButton/IconButton';
import './Faq.scss';

export default function Faq() {
  const [category, setCategory] = useState(categories[0].id);

  useEffect(() => {
    document.title = 'FAQ';
  }, []);

  return (
    <div className="faq-wrapper">
      <p className="faq-title">Frequently Asked Questions</p>
      <div className="faq-nav-container">
        {categories.map((categoryItem) => {
          return (
            <IconButton
              title={categoryItem.name}
              active={categoryItem.id === category}
              key={categoryItem.id}
              onClick={() => {
                setCategory(categoryItem.id);
              }}
              icon={categoryItem.icon}
            />
          );
        })}
      </div>
      <XpektorSlider activeSlideIndex={category}>
        {categories.map((categoryItem) => {
          return (
            <XpektorSlider.Slide
              key={categoryItem.id}
              hidden={category !== categoryItem.id}
            >
              <div className="faq-content">
                {categoryItem.questions.map((questionItem) => {
                  return (
                    <Question question={questionItem} key={questionItem.id} />
                  );
                })}
              </div>
            </XpektorSlider.Slide>
          );
        })}
      </XpektorSlider>
    </div>
  );
}
