import './XpektorBootstrapModal.scss';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
  XpektorForm,
  FormInput,
  FormButton,
} from '../../../Components/XpektorForms/XpektorForms';
import Regex from '../../../Components/Utilities/Regex';

/* eslint-disable react/prop-types */
export default function AddSupportInfoModal({
  handleClose,
  onSubmit,
  customer,
}) {
  const [supportEmail, setSupportEmail] = useState('');
  const [supportPhone, setSupportPhone] = useState('');

  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(customer).length !== 0) {
      setSupportEmail(customer.supportEmail);
      setSupportPhone(customer.supportPhoneNumber);
    }
  }, [customer]);

  const validateAndSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    let emailValid = true;
    let phoneValid = true;
    let newEmailError = '';
    let newPhoneError = '';

    if (supportEmail.length === 0) {
      newEmailError = 'Fältet kan inte vara tomt';
      emailValid = false;
    } else if (!Regex.testEmail(supportEmail)) {
      newEmailError = 'Ej giltig email';
      emailValid = false;
    }

    if (supportPhone.length === 0) {
      newPhoneError = 'Fältet kan inte vara tomt';
      phoneValid = false;
    }

    setEmailError(newEmailError);
    setPhoneError(newPhoneError);

    if (!phoneValid || !emailValid) {
      setSubmitting(false);
      return;
    }

    const supportInfo = { email: supportEmail, phone: supportPhone };

    onSubmit(supportInfo);
  };

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      centered
      keyboard={false}
      className="border-0 xpektor-bootstrap-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Information saknas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Ni saknar en emailadress och/eller ett telefonnummer som klienten ska
          kunna nå er på ifall de har frågor eller funderingar när de fyller i
          formuläret.
          <br />
          <br />
          Dessa kan ändras senare i adminvyn under fliken &quot;Allmänt&quot;.
        </p>
        <XpektorForm onSubmit={validateAndSubmit}>
          <FormInput
            label="Support email"
            type="text"
            value={supportEmail}
            onChange={(e) => setSupportEmail(e.target.value)}
            infoText={emailError}
          />
          <FormInput
            label="Support telefonnummer"
            regex={Regex.testPhoneNrInput}
            value={supportPhone}
            onChange={(e) => setSupportPhone(e.target.value)}
            infoText={phoneError}
          />
          <FormButton text="Spara och fortsätt" disabled={submitting} />
        </XpektorForm>
      </Modal.Body>
    </Modal>
  );
}
