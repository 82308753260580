/* eslint-disable no-alert */
import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  faEyeSlash,
  faEnvelope,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import './Login.scss';
import {
  MinimalForm,
  FormInput,
  FormButton,
} from '../../../Components/XpektorForms/XpektorForms';
import XpektorLogo from '../../../Components/XpektorLogo/XpektorLogo';
import AuthContext from '../../../context/auth-context';
import AuthService from '../../../Services/AuthService';
import UserService from '../../../Services/UserService';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] = useState('');
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const [loggingIn, setLoggingIn] = useState(false);

  const [sendingForgot, setSendingForgot] = useState(false);
  const [forgotRequestSent, setForgotRequestSent] = useState(false);

  const [action, setAction] = useState('login');

  const history = useHistory();
  const [, setAuthState] = useContext(AuthContext);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    setAuthState((state) => ({ ...state, user }));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    document.title = 'Logga in';
  }, []);

  const togglePasswordVisibility = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
    } else {
      setPasswordFieldType('password');
    }
  };

  const loginAPI = async () => {
    try {
      setLoggingIn(true);
      const response = await AuthService.login(email, password);
      if (response) {
        setAuthState((state) => ({ ...state, user: response }));
        history.push('/dashboard');
      }
    } catch (error) {
      setLoggingIn(false);
      if (error.response.status === 400) {
        setErrorText('Fel användarnamn eller lösenord.');
      } else if (error.response.status === 500) {
        setErrorText('Något gick fel försök igen om en stund');
      }
    }
  };
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  function validateLoginForm() {
    return email.length > 0 && password.length > 0;
  }

  function validateForgotForm() {
    return email.length > 0;
  }

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    if (!emailRegex.test(email) || !validateLoginForm()) {
      setErrorText('Fel användarnamn eller lösenord.');
      return;
    }

    try {
      localStorage.clear();
      await loginAPI();
    } catch (e) {
      alert(e.message);
    }
  };

  const handleForgotSubmit = async (event) => {
    event.preventDefault();

    if (!emailRegex.test(email)) {
      setErrorText('Ej giltig adress');
      return;
    }

    setSendingForgot(true);
    await UserService.ForgotPassword(email)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setForgotRequestSent(true);
        setErrorText('');
        setSendingForgot(false);
      });
  };

  return (
    <div className="login-wrapper">
      <div className="image-column">
        <XpektorLogo darkMode />
      </div>
      <div className="login-column">
        <div className="login-header">
          {action === 'login' ? (
            <>
              <p className="login-title">Logga in</p>
              <p className="sub-title">
                Fyll i din information för att logga in.
              </p>
            </>
          ) : (
            <p className="forgot-title">Begär nytt lösenord</p>
          )}
        </div>
        <div className="login-form-container">
          {action === 'login' ? (
            <MinimalForm onSubmit={handleLoginSubmit}>
              <FormInput
                label="Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                icon={faEnvelope}
              />
              <FormInput
                label="Lösenord"
                type={passwordFieldType}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                icon={passwordFieldType === 'password' ? faEyeSlash : faEye}
                onIconClick={() => {
                  togglePasswordVisibility();
                }}
                infoText={errorText}
              />
              <FormButton text={loggingIn ? 'Loggar in...' : 'Logga in'} />
            </MinimalForm>
          ) : (
            <>
              <p className="forgot-password-description">
                Fyll i adressen för ditt Xpektor konto för att få ett mail med
                en länk där du kan återställa ditt lösenord. Det kan ta upp till
                en minut innan du får mailet.
              </p>
              <MinimalForm onSubmit={handleForgotSubmit}>
                <FormInput
                  label="Email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  icon={faEnvelope}
                  infoText={
                    !errorText ? forgotRequestSent && 'Skickat!' : errorText
                  }
                  infoOk={!errorText}
                />
                <FormButton
                  text={sendingForgot ? 'Skickar...' : 'Skicka'}
                  disabled={!validateForgotForm() || sendingForgot}
                />
              </MinimalForm>
            </>
          )}
          <button
            className="forgot-password-button"
            type="button"
            onClick={() => {
              setAction(action === 'login' ? 'forgot-password' : 'login');
              setPassword('');
              setErrorText('');
            }}
          >
            <p>{action === 'login' ? 'Glömt lösenord?' : '<- Tillbaka'}</p>
          </button>
        </div>
        <div className="login-footer-container">
          <div className="contact">
            <p>
              Vill du testa Xpektor?{' '}
              <a
                href="https://meetings-eu1.hubspot.com/jacob-liebermann?embed=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                Boka demo
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
