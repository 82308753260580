import axios from 'axios';
import api from './ApiMiddleware';
import MonkeyTokenService from './MonkeyTokenService';

const getKYCOverview = (clientId) => {
  return api.get(`/api/v1/kyc/overview/${clientId}`);
};

const createKYCState = (clientId) => {
  return api.post(`/api/v1/kyc/create/${clientId}`);
};

const getDocumentLink = (clientId) => {
  return api.get(`/api/v1/kyc/downloadlink/${clientId}`);
};

const submitKYCForm = (
  clientId,
  stateId,
  formType,
  companyName,
  orgNr,
  boardMembers,
  otherSignatories,
  address,
  city,
  zipCode,
  contactPerson,
  settlementEmail,
  phoneNrContactPerson,
  bankAccountType,
  accountNumber,
  webAddress,
  eCommercePlatform,
  incorporationDate,
  nrEmployees,
  estimatedMonthlyRevenueInstitute,
  averageAmountInvoice,
  nrOfTransactionsInstitute,
  businessAbroadDescription,
  coreBusinessDescription,
  businessFinanceDescription,
  loanPurpose,
  foreignTransactionAmount,
  nrOfForeignTransactions,
  beneficialOwnerPersons,
  beneficialOwnerCompanies,
  companyHierarchyDescription,
  otherInfluentialOwnersPresent,
  otherInfluentialOwnerPersons,
  otherInfluentialOwnerCompanies,
  detailedLoanPurpose,
  detailedBusinessDescription,
  futurePlans,
  estimatedRevenueThreeMonths,
  estimatedRevenueSixMonths,
  estimatedRevenueTwelveMonths,
  estimatedResultThreeMonths,
  estimatedResultSixMonths,
  estimatedResultTwelveMonths,
  sellingService,
  sellingProduct,
  otherRevenueSource,
  serviceDescription,
  productDescription,
  otherRevenueSourceDescription,
  operationsOutsideEUEES,
  operationsOutsideEUEESDescription,
  currencyExchange,
  cryptoCurrencyExchange,
  revenueMonthly,
  handlingCash,
  cashMonthly,
  nrOfExtraRepayments,
  nrOfWithdrawals,
  companyHierarchyFile,
  accountProofFile,
  subjects,
  subjectIdFiles
) => {
  const formData = new FormData();
  formData.append('stateId', stateId);
  formData.append('formType', formType);
  formData.append('companyName', companyName);
  formData.append('orgNr', orgNr);
  formData.append('boardMembers', JSON.stringify(boardMembers));
  formData.append('otherSignatories', JSON.stringify(otherSignatories));
  formData.append('address', address);
  formData.append('city', city);
  formData.append('zipCode', zipCode);
  formData.append('contactPerson', contactPerson);
  formData.append('settlementEmail', settlementEmail);
  formData.append('phoneNrContactPerson', phoneNrContactPerson);
  formData.append('bankAccountType', bankAccountType);
  formData.append('accountNumber', accountNumber);
  formData.append('webAddress', webAddress);
  formData.append('eCommercePlatform', eCommercePlatform);
  formData.append('incorporationDate', incorporationDate);
  formData.append('nrEmployees', nrEmployees);
  formData.append(
    'estimatedMonthlyRevenueInstitute',
    estimatedMonthlyRevenueInstitute
  );
  formData.append('averageAmountInvoice', averageAmountInvoice);
  formData.append('nrOfTransactionsInstitute', nrOfTransactionsInstitute);
  formData.append('businessAbroadDescription', businessAbroadDescription);
  formData.append('coreBusinessDescription', coreBusinessDescription);
  formData.append('businessFinanceDescription', businessFinanceDescription);
  formData.append('loanPurpose', loanPurpose);
  formData.append('foreignTransactionAmount', foreignTransactionAmount);
  formData.append('nrOfForeignTransactions', nrOfForeignTransactions);
  formData.append(
    'beneficialOwnerPersons',
    JSON.stringify(beneficialOwnerPersons)
  );
  formData.append(
    'beneficialOwnerCompanies',
    JSON.stringify(beneficialOwnerCompanies)
  );
  formData.append('companyHierarchyDescription', companyHierarchyDescription);
  formData.append(
    'otherInfluentialOwnersPresent',
    otherInfluentialOwnersPresent
  );
  formData.append(
    'otherInfluentialOwnerPersons',
    JSON.stringify(otherInfluentialOwnerPersons)
  );
  formData.append(
    'otherInfluentialOwnerCompanies',
    JSON.stringify(otherInfluentialOwnerCompanies)
  );
  formData.append('detailedLoanPurpose', detailedLoanPurpose);
  formData.append('detailedBusinessDescription', detailedBusinessDescription);
  formData.append('futurePlans', futurePlans);
  formData.append('estimatedRevenueThreeMonths', estimatedRevenueThreeMonths);
  formData.append('estimatedRevenueSixMonths', estimatedRevenueSixMonths);
  formData.append('estimatedRevenueTwelveMonths', estimatedRevenueTwelveMonths);
  formData.append('estimatedResultThreeMonths', estimatedResultThreeMonths);
  formData.append('estimatedResultSixMonths', estimatedResultSixMonths);
  formData.append('estimatedResultTwelveMonths', estimatedResultTwelveMonths);
  formData.append('sellingService', sellingService);
  formData.append('sellingProduct', sellingProduct);
  formData.append('otherRevenueSource', otherRevenueSource);
  formData.append('serviceDescription', serviceDescription);
  formData.append('productDescription', productDescription);
  formData.append(
    'otherRevenueSourceDescription',
    otherRevenueSourceDescription
  );
  formData.append('operationsOutsideEUEES', operationsOutsideEUEES);
  formData.append(
    'operationsOutsideEUEESDescription',
    operationsOutsideEUEESDescription
  );
  formData.append('currencyExchange', currencyExchange);
  formData.append('cryptoCurrencyExchange', cryptoCurrencyExchange);
  formData.append('revenueMonthly', revenueMonthly);
  formData.append('handlingCash', handlingCash);
  formData.append('cashMonthly', cashMonthly);
  formData.append('nrOfExtraRepayments', nrOfExtraRepayments);
  formData.append('nrOfWithdrawals', nrOfWithdrawals);
  formData.append('companyHierarchyFile', companyHierarchyFile);
  formData.append('accountProofFile', accountProofFile);
  formData.append('subjects', JSON.stringify(subjects));
  subjectIdFiles.forEach((file) => {
    formData.append('subjectIdFiles', file);
  });

  return axios.post(`/api/v1/kyc/submit/${clientId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${MonkeyTokenService.getLocalAccessToken()}`,
    },
  });
};

const KYCService = {
  getKYCOverview,
  createKYCState,
  submitKYCForm,
  getDocumentLink,
};

export default KYCService;
