/* eslint-disable react/prop-types */
import './GeneralSettings.scss';
import { useState, useEffect } from 'react';
import isEmail from 'validator/lib/isEmail';
import EditablePanel from '../../../../Components/EditablePanel/EditablePanel';
import CustomerService from '../../../../Services/CustomerService';
import UserService from '../../../../Services/UserService';

export default function GeneralSettings() {
  const [editCompanyInfo, setEditCompanyInfo] = useState(false);
  const [editResponsible, setEditResponsible] = useState(false);

  const [orgNumberInput, setOrgNumberInput] = useState('');
  const [customerNameInput, setCustomerNameInput] = useState('');
  const [contactPersonInput, setContactPersonInput] = useState('');
  const [billingEmailInput, setBillingEmailInput] = useState('');
  const [phoneNumberInput, setPhoneNumberInput] = useState('');
  const [supportEmailInput, setSupportEmailInput] = useState('');
  const [supportPhoneNumberInput, setSupportPhoneNumberInput] = useState('');

  const [orgNumberError, setOrgNumberError] = useState('');
  const [customerNameError, setCustomerNameError] = useState('');
  const [billingEmailError, setBillingEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [supportEmailError, setSupportEmailError] = useState('');
  const [supportPhoneNumberError, setSupportPhoneNumberError] = useState('');

  const [customer, setCustomer] = useState({});

  useEffect(() => {
    const getData = async () => {
      const user = await UserService.getCurrentUser();
      await CustomerService.getCustomer(user.customerId)
        .then((response) => {
          setCustomer(response.data);
        })
        .catch(() => {});
    };
    getData();
  }, []);

  const saveCustomer = async (newCustomer) => {
    await CustomerService.updateCustomer(newCustomer)
      .then((response) => {
        if (response) {
          setCustomer(response.data);
          setEditCompanyInfo(false);
          setEditResponsible(false);
        }
      })
      .catch(() => {});
  };

  const validateAndSaveCompanyInfo = async () => {
    if (
      orgNumberInput !== customer.organizationNumber ||
      customerNameInput !== customer.name ||
      supportEmailInput !== customer.supportEmail ||
      supportPhoneNumberInput !== customer.supportPhoneNumber
    ) {
      const orgNumberValid = orgNumberInput.length === 10;
      const customerNameValid = customerNameInput.length > 0;
      const supportEmailValid =
        supportEmailInput.length === 0 || isEmail(supportEmailInput);

      setOrgNumberError('');
      setCustomerNameError('');

      if (!orgNumberValid) {
        setOrgNumberError('Ej giltigt org. nummer.');
      }

      if (!customerNameValid) {
        setCustomerNameError('Vänligen fyll i ett företagsnamn.');
      }

      if (!supportEmailValid) {
        setSupportEmailError('Ej giltig email-adress');
      }

      if (!orgNumberValid || !customerNameValid || !supportEmailValid) return;

      const updatedCustomer = { ...customer };
      updatedCustomer.organizationNumber = orgNumberInput;
      updatedCustomer.name = customerNameInput;
      updatedCustomer.supportEmail = supportEmailInput;
      updatedCustomer.supportPhoneNumber = supportPhoneNumberInput;

      saveCustomer(updatedCustomer);
    }
    setEditCompanyInfo(false);
  };

  const validateAndSaveResponsible = async () => {
    if (
      customerNameInput !== customer.name ||
      billingEmailInput !== customer.billingEmail ||
      phoneNumberInput !== customer.phoneNumber
    ) {
      const emailValid = isEmail(billingEmailInput);
      const phoneNumberValid = phoneNumberInput.length === 10;

      setBillingEmailError('');
      setPhoneNumberError('');

      if (!emailValid) {
        setBillingEmailError('Ej giltig email-adress');
      }

      if (!phoneNumberValid) {
        setPhoneNumberError('Ej giltigt telefonnummer');
      }

      if (!emailValid || !phoneNumberValid) return;

      const updatedCustomer = { ...customer };
      updatedCustomer.contactPerson = contactPersonInput;
      updatedCustomer.billingEmail = billingEmailInput;
      updatedCustomer.phoneNumber = phoneNumberInput;

      saveCustomer(updatedCustomer);
    }
    setEditResponsible(false);
  };

  return (
    <div className="general-settings-container">
      <EditablePanel
        title="Företagsuppgifter"
        editMode={editCompanyInfo}
        onEditClick={() => {
          setEditCompanyInfo(true);
          setOrgNumberInput(customer.organizationNumber);
          setCustomerNameInput(customer.name);
          setSupportEmailInput(customer.supportEmail);
          setSupportPhoneNumberInput(customer.supportPhoneNumber);
        }}
        onCancelClick={() => {
          setEditCompanyInfo(false);
          setOrgNumberError('');
          setCustomerNameError('');
          setSupportEmailError('');
          setSupportPhoneNumberError('');
        }}
        onConfirmClick={validateAndSaveCompanyInfo}
      >
        <EditablePanel.Entry
          label="Företagsnamn"
          value={customer.name}
          inputValue={customerNameInput}
          onInputChange={(e) => setCustomerNameInput(e.target.value)}
          editMode={editCompanyInfo}
          infoText={customerNameError}
        />
        <EditablePanel.Entry
          label="Organisationsnummer"
          value={customer.organizationNumber}
          inputValue={orgNumberInput}
          onInputChange={(e) => setOrgNumberInput(e.target.value)}
          editMode={editCompanyInfo}
          infoText={orgNumberError}
        />
        <EditablePanel.Entry
          label="Support email"
          value={customer.supportEmail}
          inputValue={supportEmailInput}
          onInputChange={(e) => setSupportEmailInput(e.target.value)}
          editMode={editCompanyInfo}
          infoText={supportEmailError}
        />
        <EditablePanel.Entry
          label="Support telefonnummer"
          value={customer.supportPhoneNumber}
          inputValue={supportPhoneNumberInput}
          onInputChange={(e) => setSupportPhoneNumberInput(e.target.value)}
          editMode={editCompanyInfo}
          infoText={supportPhoneNumberError}
        />
      </EditablePanel>
      <EditablePanel
        title="Licensansvarig"
        editMode={editResponsible}
        onEditClick={() => {
          setEditResponsible(true);
          setContactPersonInput(customer.contactPerson);
          setBillingEmailInput(customer.billingEmail);
          setPhoneNumberInput(customer.phoneNumber);
        }}
        onCancelClick={() => {
          setEditResponsible(false);
          setBillingEmailError('');
          setPhoneNumberError('');
        }}
        onConfirmClick={validateAndSaveResponsible}
      >
        <EditablePanel.Entry
          label="För & Efternamn"
          value={customer.contactPerson}
          inputValue={contactPersonInput}
          onInputChange={(e) => setContactPersonInput(e.target.value)}
          editMode={editResponsible}
        />
        <EditablePanel.Entry
          label="Faktureringsmail"
          value={customer.billingEmail}
          inputValue={billingEmailInput}
          onInputChange={(e) => setBillingEmailInput(e.target.value)}
          editMode={editResponsible}
          infoText={billingEmailError}
        />
        <EditablePanel.Entry
          label="Telefonnummer"
          value={customer.phoneNumber}
          type="number"
          inputValue={phoneNumberInput}
          onInputChange={(e) => setPhoneNumberInput(e.target.value)}
          editMode={editResponsible}
          infoText={phoneNumberError}
        />
      </EditablePanel>
    </div>
  );
}
