/* eslint-disable react/prop-types */
import './Dropdown.scss';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useClickOutside } from '../Utilities/HelperFunctions';

export default function Dropdown({
  children,
  title,
  titleIcon,
  componentIcon,
  open,
  onClick,
  onClickOutside,
  right,
  width,
}) {
  const contentRef = useRef();
  useClickOutside(contentRef, () => {
    onClickOutside();
  });
  return (
    <div
      className="xpektor-dropdown"
      ref={contentRef}
      style={width ? { minWidth: width } : {}}
    >
      <button type="button" onClick={onClick} className="drop-button">
        {titleIcon ? <FontAwesomeIcon icon={titleIcon} /> : componentIcon ?? ''}
        <p className="title">{title}</p>
        <FontAwesomeIcon
          className="drop-icon"
          icon={open ? faChevronUp : faChevronDown}
        />
      </button>
      <div
        className={`dropdown-content ${right ? 'align-right' : ''} ${
          open ? 'open' : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
}

Dropdown.Item = function Item({ content, onClick }) {
  return (
    <button type="button" onClick={onClick} className="dropdown-item">
      {content}
    </button>
  );
};
